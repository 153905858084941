import { useRef } from 'react'
import { useAccount, useChainId, useReadContract } from 'wagmi'

type ReadParams<T> = {
  contract?: any
  abi: readonly object[]
  functionName: string
  args?: unknown[]
  validate: (value: unknown) => value is T
  watch?: boolean
  loading?: boolean
  enabled?: boolean
  account?: any
  writing?: any
}

export type ContractRead<T> = {
  value?: T
  state: 'disabled' | 'enabled' | 'loading' | 'error'
  refresh?: () => void
}

export default <T>({
  contract,
  abi,
  functionName,
  args,
  validate,
  loading = false,
  enabled = true,
  account = undefined,
  writing = undefined,
}: ReadParams<T>): ContractRead<T> => {
  const value = useRef<T | undefined>()
  const { isConnected } = useAccount()
  const isEnabled = enabled && isConnected && !loading
  const chainId = useChainId()

  const contractRead = useReadContract({
    chainId,
    query: {
      enabled: isEnabled,
      refetchInterval: writing ? 200 : 0,
    },
    account,
    address: contract,
    abi,
    functionName,
    args,
  })

  if (!isEnabled) {
    return {
      state: 'disabled',
    }
  }

  const refresh = () => {
    let count = 0
    const maxExecutions = 25

    const intervalId = setInterval(() => {
      if (count >= maxExecutions) {
        clearInterval(intervalId)
      } else {
        if (contractRead.refetch) {
          contractRead.refetch()
        }
        count++
      }
    }, 200)
  }

  if (contractRead.isLoading || contractRead.isFetching) {
    if (value.current !== undefined) {
      return {
        state: 'enabled',
        value: value.current,
        refresh,
      }
    }
    return {
      state: 'loading',
    }
  }

  if (contractRead.isError || !validate(contractRead.data)) {
    // eslint-disable-next-line no-console
    console.error('Error in contractRead', contractRead.error)
    return {
      state: 'error',
    }
  }

  value.current = contractRead.data

  return {
    state: 'enabled',
    value: contractRead.data,
    refresh,
  }
}
