import { AxiosError } from 'axios'

const formatAxiosError = (error: AxiosError): string =>
  `AxiosError: ${
    error.response?.status
      ? `${error.response.config.method} ${error.response.config.url} ${error.response.status} ${JSON.stringify(
        error.response.data,
      )}`
      : `${error.message} ${error.config.method} ${error.config.url}`
  }`

export const formatError = (strings: TemplateStringsArray, ...values: any[]): string => {
  let output = `${strings[0]}`
  for (let i = 0; i < values.length; i++) {
    const value = values[i]
    let formattedValue
    if (value instanceof AxiosError) {
      formattedValue = formatAxiosError(value)
    } else if (value instanceof Error) {
      formattedValue = value.message
    } else {
      formattedValue = value
    }
    output += `${formattedValue}${strings[i + 1]}`
  }
  return output
}
