import { Card, Space, Text } from '@app/components'
import { CardProps, SpaceProps, TextProps } from '@app/types'
import styled from 'styled-components'

export const StyledCard = styled((props: CardProps) => <Card {...props} />)`
  align-items: center;
  background: ${(props) => props.theme.color.grey};
  cursor: pointer;
  display: flex;
  height: 220px;
  justify-content: center;
  && {
    .ant-card-body {
      padding: 0px;
    }
  }
`

export const StyledSpace = styled((props: SpaceProps) => <Space {...props} />)`
  align-items: center;
  display: flex;
`

export const StyledTitleText = styled((props: TextProps) => {
  return <Text {...props} />
})`
  font-size: 16px;
`

export const StyledDescText = styled((props: TextProps) => {
  return <Text {...props} />
})`
  font-size: 12px;
`
