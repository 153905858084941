export const DUMMY_ACCOUNT_DATA = {
  lastName: 'Johnson',
  email: 'johnson@gmail.com',
  taxID: '746386947',
  taxState: 'AZ',
  taxCountry: 'US',
  phoneNumber: '7589654376',
  streetAddress_1: '1234 Street Ave',
  city: 'Test',
  state: 'AZ',
  postalCode: '12345',
  country: 'US',
}
