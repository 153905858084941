import { EDynamicFormField, IField } from '@app/modules'

export const ADD_WALLET_MANUALLY_FORM: IField[] = [
  {
    label: 'Nickname',
    name: 'nickname',
    placeholder: 'Enter name of the wallet address',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Wallet Address',
    name: 'walletAddress',
    placeholder: 'Enter wallet address',
    rules: [{ required: true }],
    disabled: true,
    type: EDynamicFormField.INPUT,
  },
  {
    name: 'button',
    title: 'Add',
    type: EDynamicFormField.PRIMARY_BUTTON,
  },
]
