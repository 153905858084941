import React from 'react'

import {
  DFCheckbox,
  DFDatePicker,
  DFDefaultButton,
  DFEmailInput,
  DFEntityDatePicker,
  DFIconButton,
  DFInput,
  DFInputNumber,
  DFNavigation,
  DFPasswordInput,
  DFPrimaryButton,
  DFRawText,
  DFSecondaryButton,
  DFSelect,
  DFTextDivider,
  DFUploader,
} from './components'
import { DFImageCard } from './components/image-card'
import { EDynamicFormField } from './types'

export const DynamicInput: React.FC<any> = ({ field, onCancel, onSubmit, disabled, validateStatus, form }) => {
  switch (field.type as EDynamicFormField) {
    case EDynamicFormField.INPUT: {
      return <DFInput field={field} disabled={disabled} validateStatus={validateStatus} />
    }
    case EDynamicFormField.DATE: {
      return <DFDatePicker field={field} disabled={disabled} />
    }
    case EDynamicFormField.ENTITYDATE: {
      return <DFEntityDatePicker field={field} disabled={disabled} />
    }
    case EDynamicFormField.SELECT: {
      return <DFSelect field={field} disabled={disabled} />
    }
    case EDynamicFormField.EMAIL: {
      return <DFEmailInput field={field} disabled={disabled} />
    }
    case EDynamicFormField.PASSWORD: {
      return <DFPasswordInput field={field} disabled={disabled} />
    }
    case EDynamicFormField.CHECKBOX: {
      return <DFCheckbox field={field} disabled={disabled} />
    }
    case EDynamicFormField.NAVIGATION: {
      return <DFNavigation field={field} disabled={disabled} />
    }
    case EDynamicFormField.RAW_TEXT: {
      return <DFRawText field={field} disabled={disabled} />
    }
    case EDynamicFormField.TEXT_DIVIDER: {
      return <DFTextDivider field={field} disabled={disabled} />
    }
    case EDynamicFormField.IMAGE_CARD: {
      return <DFImageCard icon={field.icon} title={field.title} />
    }
    case EDynamicFormField.PRIMARY_BUTTON: {
      return <DFPrimaryButton field={field} onSubmit={onSubmit} />
    }
    case EDynamicFormField.SECONDARY_BUTTON: {
      return <DFSecondaryButton field={field} onSubmit={onSubmit} />
    }
    case EDynamicFormField.DEFAULT_BUTTON: {
      return <DFDefaultButton field={field} onCancel={onCancel} />
    }
    case EDynamicFormField.ICON_BUTTON: {
      return <DFIconButton field={field} onSubmit={onSubmit} />
    }
    case EDynamicFormField.UPLOADER: {
      return <DFUploader title={field.title} name={field.name} form={form} />
    }
    case EDynamicFormField.NUMBER: {
      return <DFInputNumber field={field} disabled={disabled} />
    }
    default: {
      return <></>
    }
  }
}
