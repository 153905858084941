import { EFirestoreCollection } from '@app/services/shared'
import { arrayRemove, arrayUnion, collection, doc, setDoc, updateDoc } from 'firebase/firestore'

import { db } from '../firebase'
import { getHiddenAccounts, getWallets } from './read'

export const updateUser = async (uid, update) => {
  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), update)
}

export const addUser = async (uid, userData: { email: string }) => {
  await setDoc(doc(db, EFirestoreCollection.ACCOUNTS, uid), {
    email: userData.email,
    registrationStepNo: 0,
    wallets: [],
    errors: [],
    referralCode: doc(collection(db, 'NotARealCollection')).id,
    referralLeadsCount: 0,
    referralConversionsCount: 0,
    referralConversionsPaidCount: 0,
    referralWaitEarned: 0,
  })
}

export const addWallet = async (uid, wallet, nickname) => {
  const wallets = (await getWallets(uid)) || []
  const isWalletExist = wallets.find((item) => item.Wallet === wallet)
  if (isWalletExist) return false

  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), {
    wallets: arrayUnion({ Nickname: nickname, Wallet: wallet }),
  })
  return true
}

export const addHiddenAccount = async (uid, account) => {
  const hiddenAccounts = (await getHiddenAccounts(uid)) || []
  const isHiddenAccountExist = hiddenAccounts.find((item) => item.id === account)
  if (isHiddenAccountExist) return false

  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), {
    hiddenAccounts: arrayUnion({ id: account, hidden: true }),
  })
  return true
}

export const addDiscountWallet = async (uid, wallet) => {
  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), {
    discountWallet: wallet,
  })
}

export const removeWallet = async (uid, wallet, nickname) => {
  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), {
    wallets: arrayRemove({ Nickname: nickname, Wallet: wallet }),
  })
}

export const addTransaction = async (transactionId, transaction) => {
  await setDoc(doc(db, EFirestoreCollection.TRANSACTIONS, transactionId), transaction)
}

export const addErrors = async (uid, err, status) => {
  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), {
    errors: arrayUnion({ Error: err, Status: status }),
  })
}

export const updateErrors = async (uid, err, status) => {
  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), {
    errors: arrayRemove({ Error: err, Status: 'Unresolved' }),
  })
  await updateDoc(doc(db, EFirestoreCollection.ACCOUNTS, `${uid}`), {
    errors: arrayUnion({ Error: err, Status: status }),
  })
}
