export const COUNTRIES = [
  //...(process.env.NEXT_PUBLIC_ENV === 'development' ? [{ label: 'US', value: 'US' }] : []),
  { label: 'US', value: 'US' },
  { label: 'UK', value: 'GB' },
  { label: 'Albania', value: 'AL' },
  { label: 'Algeria', value: 'DZ' },
  { label: 'Angola', value: 'AO' },
  { label: 'Antigua and Barbuda', value: 'AG' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Aruba', value: 'AW' },
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Azerbaijan', value: 'AZ' },
  { label: 'Bahamas', value: 'BS' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Barbados', value: 'BB' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Belize', value: 'BZ' },
  { label: 'Benin', value: 'BJ' },
  { label: 'Bhutan', value: 'BT' },
  { label: 'Bolivia', value: 'BO' },
  { label: 'Bosnia and Herzegovina', value: 'BA' },
  { label: 'Botswana', value: 'BW' },
  { label: 'Brazil', value: 'BR' },
  { label: 'British Virgin Islands', value: 'VG' },
  { label: 'Brunei', value: 'BN' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Burkina Faso', value: 'BF' },
  { label: 'Cambodia', value: 'KH' },
  { label: 'Cameroon', value: 'CM' },
  { label: 'Canada', value: 'CA' },
  { label: 'Cayman Islands', value: 'KY' },
  { label: 'Chad', value: 'TD' },
  { label: 'Chile', value: 'CL' },
  { label: 'China', value: 'CN' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Congo', value: 'CG' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Cote d`Ivoire', value: 'CI' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cuba', value: 'CU' },
  { label: 'Curacao', value: 'CW' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Djibouti', value: 'DJ' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Egypt', value: 'EG' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'Equatorial Guinea', value: 'GQ' },
  { label: 'Eritrea', value: 'ER' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Eswatini', value: 'SZ' },
  { label: 'Ethiopia', value: 'ET' },
  { label: 'Fiji', value: 'FJ' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Gabon', value: 'GA' },
  { label: 'The Gambia', value: 'GM' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Germany', value: 'DE' },
  { label: 'Ghana', value: 'GH' },
  { label: 'Greece', value: 'GR' },
  { label: 'Greenland', value: 'GL' },
  { label: 'Grenada', value: 'GD' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'Guinea', value: 'GN' },
  { label: 'Guyana', value: 'GY' },
  { label: 'Honduras', value: 'HN' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Iceland', value: 'IS' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Isle of Man', value: 'IM' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Jamaica', value: 'JM' },
  { label: 'Japan', value: 'JP' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kosovo', value: 'XK' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kyrgyzstan', value: 'KG' },
  { label: 'Laos', value: 'LA' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Lesotho', value: 'LS' },
  { label: 'Liberia', value: 'LR' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Macau', value: 'MO' },
  { label: 'Madagascar', value: 'MG' },
  { label: 'Malawi', value: 'MW' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Maldives', value: 'MV' },
  { label: 'Mali', value: 'ML' },
  { label: 'Malta', value: 'MT' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Mauritania', value: 'MR' },
  { label: 'Mauritius', value: 'MU' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Moldova', value: 'MD' },
  { label: 'Monaco', value: 'MC' },
  { label: 'Mongolia', value: 'MN' },
  { label: 'Montenegro', value: 'ME' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Mozambique', value: 'MZ' },
  { label: 'Myanmar', value: 'MM' },
  { label: 'Namibia', value: 'NA' },
  { label: 'Nepal', value: 'NP' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Niger', value: 'NE' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'North Macedonia', value: 'MK' },
  { label: 'Norway', value: 'NO' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Panama', value: 'PA' },
  { label: 'Papua New Guinea', value: 'PG' },
  { label: 'Paraguay', value: 'PY' },
  { label: 'Peru', value: 'PE' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Qatar', value: 'QA' },
  { label: 'Romania', value: 'RO' },
  { label: 'Rwanda', value: 'RW' },
  { label: 'Saint Kitts and Nevis', value: 'KN' },
  { label: 'Saint Lucia', value: 'LC' },
  { label: 'Senegal', value: 'SN' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Sierra Leone', value: 'SL' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Solomon Islands', value: 'SB' },
  { label: 'Somalia', value: 'SO' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'South Korea', value: 'KR' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Suriname', value: 'SR' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Taiwan', value: 'TW' },
  { label: 'Tajikistan', value: 'TJ' },
  { label: 'Tanzania', value: 'TZ' },
  { label: 'Thailand', value: 'TH' },
  { label: 'Togo', value: 'TG' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Tunisia', value: 'TN' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Turkmenistan', value: 'TM' },
  { label: 'Turks and Caicos Islands', value: 'TC' },
  { label: 'UAE', value: 'AE' },
  { label: 'Uganda', value: 'UG' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Uzbekistan', value: 'UZ' },
  { label: 'Vietnam', value: 'VN' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Zambia', value: 'ZM' },
]

export const HIFI_COUNTRIES = [
  { label: 'US', value: 'USA', dial_code: '+1' },
  { label: 'UK', value: 'GBR', dial_code: '+44' },
  { label: 'Albania', value: 'ALB', dial_code: '+355' },
  { label: 'Algeria', value: 'DZA', dial_code: '+213' },
  { label: 'Angola', value: 'AGO', dial_code: '+244' },
  { label: 'Antigua and Barbuda', value: 'ATG', dial_code: '+1-268' },
  { label: 'Argentina', value: 'ARG', dial_code: '+54' },
  { label: 'Armenia', value: 'ARM', dial_code: '+374' },
  { label: 'Aruba', value: 'ABW', dial_code: '+297' },
  { label: 'Australia', value: 'AUS', dial_code: '+61' },
  { label: 'Austria', value: 'AUT', dial_code: '+43' },
  { label: 'Azerbaijan', value: 'AZE', dial_code: '+994' },
  { label: 'Bahamas', value: 'BHS', dial_code: '+1-242' },
  { label: 'Bahrain', value: 'BHR', dial_code: '+973' },
  { label: 'Bangladesh', value: 'BGD', dial_code: '+880' },
  { label: 'Barbados', value: 'BRB', dial_code: '+1-246' },
  { label: 'Belgium', value: 'BEL', dial_code: '+32' },
  { label: 'Belize', value: 'BLZ', dial_code: '+501' },
  { label: 'Benin', value: 'BEN', dial_code: '+229' },
  { label: 'Bhutan', value: 'BTN', dial_code: '+975' },
  { label: 'Bolivia', value: 'BOL', dial_code: '+591' },
  { label: 'Bosnia and Herzegovina', value: 'BIH', dial_code: '+387' },
  { label: 'Botswana', value: 'BWA', dial_code: '+267' },
  { label: 'Brazil', value: 'BRA', dial_code: '+55' },
  { label: 'British Virgin Islands', value: 'VGB', dial_code: '+1-284' },
  { label: 'Brunei', value: 'BRN', dial_code: '+673' },
  { label: 'Bulgaria', value: 'BGR', dial_code: '+359' },
  { label: 'Burkina Faso', value: 'BFA', dial_code: '+226' },
  { label: 'Cambodia', value: 'KHM', dial_code: '+855' },
  { label: 'Cameroon', value: 'CMR', dial_code: '+237' },
  { label: 'Canada', value: 'CAN', dial_code: '+1' },
  { label: 'Cayman Islands', value: 'CYM', dial_code: '+1-345' },
  { label: 'Chad', value: 'TCD', dial_code: '+235' },
  { label: 'Chile', value: 'CHL', dial_code: '+56' },
  { label: 'China', value: 'CHN', dial_code: '+86' },
  { label: 'Colombia', value: 'COL', dial_code: '+57' },
  { label: 'Congo', value: 'COG', dial_code: '+242' },
  { label: 'Costa Rica', value: 'CRI', dial_code: '+506' },
  { label: 'Cote d`Ivoire', value: 'CIV', dial_code: '+225' },
  { label: 'Croatia', value: 'HRV', dial_code: '+385' },
  { label: 'Cuba', value: 'CUB', dial_code: '+53' },
  { label: 'Curacao', value: 'CUW', dial_code: '+599' },
  { label: 'Cyprus', value: 'CYP', dial_code: '+357' },
  { label: 'Czech Republic', value: 'CZE', dial_code: '+420' },
  { label: 'Denmark', value: 'DNK', dial_code: '+45' },
  { label: 'Djibouti', value: 'DJI', dial_code: '+253' },
  { label: 'Dominican Republic', value: 'DOM', dial_code: '+1-809' },
  { label: 'Ecuador', value: 'ECU', dial_code: '+593' },
  { label: 'Egypt', value: 'EGY', dial_code: '+20' },
  { label: 'El Salvador', value: 'SLV', dial_code: '+503' },
  { label: 'Equatorial Guinea', value: 'GNQ', dial_code: '+240' },
  { label: 'Eritrea', value: 'ERI', dial_code: '+291' },
  { label: 'Estonia', value: 'EST', dial_code: '+372' },
  { label: 'Eswatini', value: 'SWZ', dial_code: '+268' },
  { label: 'Ethiopia', value: 'ETH', dial_code: '+251' },
  { label: 'Fiji', value: 'FJI', dial_code: '+679' },
  { label: 'Finland', value: 'FIN', dial_code: '+358' },
  { label: 'France', value: 'FRA', dial_code: '+33' },
  { label: 'Gabon', value: 'GAB', dial_code: '+241' },
  { label: 'The Gambia', value: 'GMB', dial_code: '+220' },
  { label: 'Georgia', value: 'GEO', dial_code: '+995' },
  { label: 'Germany', value: 'DEU', dial_code: '+49' },
  { label: 'Ghana', value: 'GHA', dial_code: '+233' },
  { label: 'Greece', value: 'GRC', dial_code: '+30' },
  { label: 'Greenland', value: 'GRL', dial_code: '+299' },
  { label: 'Grenada', value: 'GRD', dial_code: '+1-473' },
  { label: 'Guatemala', value: 'GTM', dial_code: '+502' },
  { label: 'Guinea', value: 'GIN', dial_code: '+224' },
  { label: 'Guyana', value: 'GUY', dial_code: '+592' },
  { label: 'Honduras', value: 'HND', dial_code: '+504' },
  { label: 'Hong Kong', value: 'HKG', dial_code: '+852' },
  { label: 'Hungary', value: 'HUN', dial_code: '+36' },
  { label: 'Iceland', value: 'ISL', dial_code: '+354' },
  { label: 'India', value: 'IND', dial_code: '+91' },
  { label: 'Indonesia', value: 'IDN', dial_code: '+62' },
  { label: 'Ireland', value: 'IRL', dial_code: '+353' },
  { label: 'Isle of Man', value: 'IMN', dial_code: '+44-1624' },
  { label: 'Israel', value: 'ISR', dial_code: '+972' },
  { label: 'Italy', value: 'ITA', dial_code: '+39' },
  { label: 'Jamaica', value: 'JAM', dial_code: '+1-876' },
  { label: 'Japan', value: 'JPN', dial_code: '+81' },
  { label: 'Jordan', value: 'JOR', dial_code: '+962' },
  { label: 'Kazakhstan', value: 'KAZ', dial_code: '+7' },
  { label: 'Kenya', value: 'KEN', dial_code: '+254' },
  { label: 'Kosovo', value: 'XKX', dial_code: '+383' },
  { label: 'Kuwait', value: 'KWT', dial_code: '+965' },
  { label: 'Kyrgyzstan', value: 'KGZ', dial_code: '+996' },
  { label: 'Laos', value: 'LAO', dial_code: '+856' },
  { label: 'Latvia', value: 'LVA', dial_code: '+371' },
  { label: 'Lesotho', value: 'LSO', dial_code: '+266' },
  { label: 'Liberia', value: 'LBR', dial_code: '+231' },
  { label: 'Lithuania', value: 'LTU', dial_code: '+370' },
  { label: 'Luxembourg', value: 'LUX', dial_code: '+352' },
  { label: 'Macau', value: 'MAC', dial_code: '+853' },
  { label: 'Madagascar', value: 'MDG', dial_code: '+261' },
  { label: 'Malawi', value: 'MWI', dial_code: '+265' },
  { label: 'Malaysia', value: 'MYS', dial_code: '+60' },
  { label: 'Maldives', value: 'MDV', dial_code: '+960' },
  { label: 'Mali', value: 'MLI', dial_code: '+223' },
  { label: 'Malta', value: 'MLT', dial_code: '+356' },
  { label: 'Marshall Islands', value: 'MHL', dial_code: '+692' },
  { label: 'Mauritania', value: 'MRT', dial_code: '+222' },
  { label: 'Mauritius', value: 'MUS', dial_code: '+230' },
  { label: 'Mexico', value: 'MEX', dial_code: '+52' },
  { label: 'Moldova', value: 'MDA', dial_code: '+373' },
  { label: 'Monaco', value: 'MCO', dial_code: '+377' },
  { label: 'Mongolia', value: 'MNG', dial_code: '+976' },
  { label: 'Montenegro', value: 'MNE', dial_code: '+382' },
  { label: 'Morocco', value: 'MAR', dial_code: '+212' },
  { label: 'Mozambique', value: 'MOZ', dial_code: '+258' },
  { label: 'Myanmar', value: 'MMR', dial_code: '+95' },
  { label: 'Namibia', value: 'NAM', dial_code: '+264' },
  { label: 'Nepal', value: 'NPL', dial_code: '+977' },
  { label: 'Netherlands', value: 'NLD', dial_code: '+31' },
  { label: 'New Zealand', value: 'NZL', dial_code: '+64' },
  { label: 'Niger', value: 'NER', dial_code: '+227' },
  { label: 'Nigeria', value: 'NGA', dial_code: '+234' },
  { label: 'North Macedonia', value: 'MKD', dial_code: '+389' },
  { label: 'Norway', value: 'NOR', dial_code: '+47' },
  { label: 'Oman', value: 'OMN', dial_code: '+968' },
  { label: 'Pakistan', value: 'PAK', dial_code: '+92' },
  { label: 'Panama', value: 'PAN', dial_code: '+507' },
  { label: 'Papua New Guinea', value: 'PNG', dial_code: '+675' },
  { label: 'Paraguay', value: 'PRY', dial_code: '+595' },
  { label: 'Peru', value: 'PER', dial_code: '+51' },
  { label: 'Philippines', value: 'PHL', dial_code: '+63' },
  { label: 'Poland', value: 'POL', dial_code: '+48' },
  { label: 'Portugal', value: 'PRT', dial_code: '+351' },
  { label: 'Qatar', value: 'QAT', dial_code: '+974' },
  { label: 'Romania', value: 'ROU', dial_code: '+40' },
  { label: 'Rwanda', value: 'RWA', dial_code: '+250' },
  { label: 'Saint Kitts and Nevis', value: 'KNA', dial_code: '+1-869' },
  { label: 'Saint Lucia', value: 'LCA', dial_code: '+1-758' },
  { label: 'Senegal', value: 'SEN', dial_code: '+221' },
  { label: 'Serbia', value: 'SRB', dial_code: '+381' },
  { label: 'Sierra Leone', value: 'SLE', dial_code: '+232' },
  { label: 'Singapore', value: 'SGP', dial_code: '+65' },
  { label: 'Slovakia', value: 'SVK', dial_code: '+421' },
  { label: 'Slovenia', value: 'SVN', dial_code: '+386' },
  { label: 'Solomon Islands', value: 'SLB', dial_code: '+677' },
  { label: 'Somalia', value: 'SOM', dial_code: '+252' },
  { label: 'South Africa', value: 'ZAF', dial_code: '+27' },
  { label: 'South Korea', value: 'KOR', dial_code: '+82' },
  { label: 'Spain', value: 'ESP', dial_code: '+34' },
  { label: 'Sri Lanka', value: 'LKA', dial_code: '+94' },
  { label: 'Suriname', value: 'SUR', dial_code: '+597' },
  { label: 'Sweden', value: 'SWE', dial_code: '+46' },
  { label: 'Switzerland', value: 'CHE', dial_code: '+41' },
  { label: 'Taiwan', value: 'TWN', dial_code: '+886' },
  { label: 'Tajikistan', value: 'TJK', dial_code: '+992' },
  { label: 'Tanzania', value: 'TZA', dial_code: '+255' },
  { label: 'Thailand', value: 'THA', dial_code: '+66' },
  { label: 'Togo', value: 'TGO', dial_code: '+228' },
  { label: 'Trinidad and Tobago', value: 'TTO', dial_code: '+1-868' },
  { label: 'Tunisia', value: 'TUN', dial_code: '+216' },
  { label: 'Turkey', value: 'TUR', dial_code: '+90' },
  { label: 'Turkmenistan', value: 'TKM', dial_code: '+993' },
  { label: 'Turks and Caicos Islands', value: 'TCA', dial_code: '+1-649' },
  { label: 'UAE', value: 'ARE', dial_code: '+971' },
  { label: 'Uganda', value: 'UGA', dial_code: '+256' },
  { label: 'Ukraine', value: 'UKR', dial_code: '+380' },
  { label: 'Uruguay', value: 'URY', dial_code: '+598' },
  { label: 'Uzbekistan', value: 'UZB', dial_code: '+998' },
  { label: 'Vietnam', value: 'VNM', dial_code: '+84' },
  { label: 'Virgin Islands', value: 'VIR', dial_code: '+1-340' },
  { label: 'Zambia', value: 'ZMB', dial_code: '+260' },
]

export const COUNTRIES_PHONE = [
  //...(process.env.NEXT_PUBLIC_ENV === 'development' ? [{ label: 'US', value: 'US' }] : []),
  { label: 'US (+1)', value: 'US' },
  { label: 'CA (+1)', value: 'CA' },
  { label: 'BS (+1 242)', value: 'BS' }, //Bahamas
  { label: 'BB (+1 246)', value: 'BB' }, //Barbados
  { label: 'VG (+1 284)', value: 'VG' }, //British Virgin Islands
  { label: 'VI (+1 340)', value: 'VI' }, //Virgin Islands
  { label: 'BM (+1 441)', value: 'BM' }, //Bermuda
  { label: 'DO (+1 809)', value: 'DO' }, //Dominican Republic
  {
    label: 'EG (+20)',
    value: 'EG',
  },
  {
    label: 'ZA (+27)',
    value: 'ZA',
  },
  {
    label: 'GR (+30)',
    value: 'GR',
  },
  {
    label: 'NL (+31)',
    value: 'NL',
  },
  {
    label: 'BE (+32)',
    value: 'BE',
  },
  {
    label: 'FR (+33)',
    value: 'FR',
  },
  {
    label: 'ES (+34)',
    value: 'ES',
  },
  {
    label: 'HU (+36)',
    value: 'HU',
  },
  {
    label: 'IT (+39)',
    value: 'IT',
  },
  {
    label: 'RO (+40)',
    value: 'RO',
  },
  {
    label: 'CH (+41)',
    value: 'CH',
  },
  {
    label: 'AT (+43)',
    value: 'AT',
  },
  {
    label: 'UK (+44)',
    value: 'GB',
  },
  {
    label: 'IM (+44)',
    value: 'IM',
  },
  {
    label: 'DK (+45)',
    value: 'DK',
  },
  {
    label: 'SE (+46)',
    value: 'SE',
  },
  {
    label: 'NO (+47)',
    value: 'NO',
  },
  {
    label: 'PL (+48)',
    value: 'PL',
  },
  {
    label: 'DE (+49)',
    value: 'DE',
  },
  {
    label: 'TC (49)',
    value: 'TC',
  },
  {
    label: 'PE (+51)',
    value: 'PE',
  },
  {
    label: 'MX (+52)',
    value: 'MX',
  },
  {
    label: 'CU (+53)',
    value: 'CU',
  },
  {
    label: 'AR (+54)',
    value: 'AR',
  },
  {
    label: 'BR (+55)',
    value: 'BR',
  },
  {
    label: 'CL (+56)',
    value: 'CL',
  },
  {
    label: 'LC (58)',
    value: 'LC',
  },
  {
    label: 'MY (+60)',
    value: 'MY',
  },
  {
    label: 'AU (+61)',
    value: 'AU',
  },
  {
    label: 'ID (+62)',
    value: 'ID',
  },
  {
    label: 'PH (+63)',
    value: 'PH',
  },
  {
    label: 'NZ (+64)',
    value: 'NZ',
  },
  {
    label: 'SG (+65)',
    value: 'SG',
  },
  {
    label: 'TH (+66)',
    value: 'TH',
  },
  {
    label: 'AG (+68)',
    value: 'AG',
  },
  {
    label: 'TT (68)',
    value: 'TT',
  },
  {
    label: 'KN (69)',
    value: 'KN',
  },
  {
    label: 'GD (73)',
    value: 'GD',
  },
  {
    label: 'JM (76)',
    value: 'JM',
  },
  {
    label: 'KZ (+77)',
    value: 'KZ',
  },
  {
    label: 'JP (+81)',
    value: 'JP',
  },
  {
    label: 'KR (+82)',
    value: 'KR',
  },
  {
    label: 'VN (+84)',
    value: 'VN',
  },
  {
    label: 'CN (+86)',
    value: 'CN',
  },
  {
    label: 'PR (87)',
    value: 'PR',
  },
  {
    label: 'TR (+90)',
    value: 'TR',
  },
  {
    label: 'IN (+91)',
    value: 'IN',
  },
  {
    label: 'LK (+94)',
    value: 'LK',
  },
  {
    label: 'MM (+95)',
    value: 'MM',
  },
  {
    label: 'MA (+212)',
    value: 'MA',
  },
  {
    label: 'DZ (+213)',
    value: 'DZ',
  },
  {
    label: 'TN (+216)',
    value: 'TN',
  },
  {
    label: 'GM (+220)',
    value: 'GM',
  },
  {
    label: 'SN (+221)',
    value: 'SN',
  },
  {
    label: 'MR (+222)',
    value: 'MR',
  },
  {
    label: 'ML (+223)',
    value: 'ML',
  },
  {
    label: 'GN (+224)',
    value: 'GN',
  },
  {
    label: 'CI (+225)',
    value: 'CI',
  },
  {
    label: 'BF (+226)',
    value: 'BF',
  },
  {
    label: 'NE (+227)',
    value: 'NE',
  },
  {
    label: 'TG (+228)',
    value: 'TG',
  },
  {
    label: 'BJ (+229)',
    value: 'BJ',
  },
  {
    label: 'MU (+230)',
    value: 'MU',
  },
  {
    label: 'LR (+231)',
    value: 'LR',
  },
  {
    label: 'SL (+232)',
    value: 'SL',
  },
  {
    label: 'GH (+233)',
    value: 'GH',
  },
  {
    label: 'NG (+234)',
    value: 'NG',
  },
  {
    label: 'TD (+235)',
    value: 'TD',
  },
  {
    label: 'CM (+237)',
    value: 'CM',
  },
  {
    label: 'GQ (+240)',
    value: 'GQ',
  },
  {
    label: 'GA (+241)',
    value: 'GA',
  },
  {
    label: 'CG (+242)',
    value: 'CG',
  },
  {
    label: 'CG (+242)',
    value: 'CG',
  },
  {
    label: 'AO (+244)',
    value: 'AO',
  },
  {
    label: 'RW (+250)',
    value: 'RW',
  },
  {
    label: 'ET (+251)',
    value: 'ET',
  },
  {
    label: 'DJ (+253)',
    value: 'DJ',
  },
  {
    label: 'KE (+254)',
    value: 'KE',
  },
  {
    label: 'TZ (+255)',
    value: 'TZ',
  },
  {
    label: 'UG (+256)',
    value: 'UG',
  },
  {
    label: 'MZ (+258)',
    value: 'MZ',
  },
  {
    label: 'ZM (+260)',
    value: 'ZM',
  },
  {
    label: 'MG (+261)',
    value: 'MG',
  },
  {
    label: 'NA (+264)',
    value: 'NA',
  },
  {
    label: 'MW (+265)',
    value: 'MW',
  },
  {
    label: 'LS (+266)',
    value: 'LS',
  },
  {
    label: 'BW (+267)',
    value: 'BW',
  },
  {
    label: 'SZ (+268)',
    value: 'SZ',
  },
  {
    label: 'KM (+269)',
    value: 'KM',
  },
  {
    label: 'ER (+291)',
    value: 'ER',
  },
  {
    label: 'AW (+297)',
    value: 'AW',
  },
  {
    label: 'GL (+299)',
    value: 'GL',
  },
  {
    label: 'KY (+345)',
    value: 'KY',
  },
  {
    label: 'PT (+351)',
    value: 'PT',
  },
  {
    label: 'LU (+352)',
    value: 'LU',
  },
  {
    label: 'IE (+353)',
    value: 'IE',
  },
  {
    label: 'IS (+354)',
    value: 'IS',
  },
  {
    label: 'AL (+355)',
    value: 'AL',
  },
  {
    label: 'MT (+356)',
    value: 'MT',
  },
  {
    label: 'CY (+357)',
    value: 'CY',
  },
  {
    label: 'FI (+358)',
    value: 'FI',
  },
  {
    label: 'BG (+359)',
    value: 'BG',
  },
  {
    label: 'LT (+370)',
    value: 'LT',
  },
  {
    label: 'LV (+371)',
    value: 'LV',
  },
  {
    label: 'EE (+372)',
    value: 'EE',
  },
  {
    label: 'MD (+373)',
    value: 'MD',
  },
  {
    label: 'AM (+374)',
    value: 'AM',
  },
  {
    label: 'MC (+377)',
    value: 'MC',
  },
  {
    label: 'RS (+381)',
    value: 'RS',
  },
  {
    label: 'XK (+383)',
    value: 'XK',
  },
  {
    label: 'HR (+385)',
    value: 'HR',
  },
  {
    label: 'SI (+386)',
    value: 'SI',
  },
  {
    label: 'BA (+387)',
    value: 'BA',
  },
  {
    label: 'MK (+389)',
    value: 'MK',
  },
  {
    label: 'CZ (+420)',
    value: 'CZ',
  },
  {
    label: 'SK (+421)',
    value: 'SK',
  },
  {
    label: 'LI (+423)',
    value: 'LI',
  },
  {
    label: 'GT (+502)',
    value: 'GT',
  },
  {
    label: 'SV (+503)',
    value: 'SV',
  },
  {
    label: 'HN (+504)',
    value: 'HN',
  },
  {
    label: 'CR (+506)',
    value: 'CR',
  },
  {
    label: 'PA (+507)',
    value: 'PA',
  },
  {
    label: 'BO (+591)',
    value: 'BO',
  },
  {
    label: 'GY (+592)',
    value: 'GY',
  },
  {
    label: 'EC (+593)',
    value: 'EC',
  },
  {
    label: 'PY (+595)',
    value: 'PY',
  },
  {
    label: 'SR (+597)',
    value: 'SR',
  },
  {
    label: 'UY (+598)',
    value: 'UY',
  },
  {
    label: 'CW (+599)',
    value: 'CW',
  },
  {
    label: 'TL (+670)',
    value: 'TL',
  },
  {
    label: 'BN (+673)',
    value: 'BN',
  },
  {
    label: 'PG (+675)',
    value: 'PG',
  },
  {
    label: 'SB (+677)',
    value: 'SB',
  },
  {
    label: 'FJ (+679)',
    value: 'FJ',
  },
  {
    label: 'MH (+692)',
    value: 'MH',
  },
  {
    label: 'HK (+852)',
    value: 'HK',
  },
  {
    label: 'MO (+853)',
    value: 'MO',
  },
  {
    label: 'KH (+855)',
    value: 'KH',
  },
  {
    label: 'LA (+856)',
    value: 'LA',
  },
  {
    label: 'BD (+880)',
    value: 'BD',
  },
  {
    label: 'TW (+886)',
    value: 'TW',
  },
  {
    label: 'JO (+962)',
    value: 'JO',
  },
  {
    label: 'KW (+965)',
    value: 'KW',
  },
  {
    label: 'SA (+966)',
    value: 'SA',
  },
  {
    label: 'OM (+968)',
    value: 'OM',
  },
  {
    label: 'AE (+971)',
    value: 'AE',
  },
  {
    label: 'IL (+972)',
    value: 'IL',
  },
  {
    label: 'BH (+973)',
    value: 'BH',
  },
  {
    label: 'QA (+974)',
    value: 'QA',
  },
  {
    label: 'QA (+974)',
    value: 'QA',
  },
  {
    label: 'BT (+975)',
    value: 'BT',
  },
  {
    label: 'MN (+976)',
    value: 'MN',
  },
  {
    label: 'NP (+977)',
    value: 'NP',
  },
  {
    label: 'TJ (+992)',
    value: 'TJ',
  },
  {
    label: 'TM (+993)',
    value: 'TM',
  },
  {
    label: 'AZ (+994)',
    value: 'AZ',
  },
  {
    label: 'GE (+995)',
    value: 'GE',
  },
  {
    label: 'KG (+996)',
    value: 'KG',
  },
  {
    label: 'UZ (+998)',
    value: 'UZ',
  },
]

export const HIFI_COUNTRIES_PHONE = [
  //...(process.env.NEXT_PUBLIC_ENV === 'development' ? [{ label: 'US', value: 'US' }] : []),
  { label: 'US (+1)', value: 'US' },
]
