/* eslint-disable @typescript-eslint/no-unused-vars */
import { Col, Row } from '@app/components'
import { IStore } from '@app/redux'
import RainbowKitButton from 'libs/services/new_wallet/RainbowKitButton'
import { useSelector } from 'react-redux'

import { CoastWordMarkIcon } from '~public'

import { StyledPageHeader, StyledSpace } from './elements'
import { ProfileMenu } from './profile-menu'

export const NavBar = () => {
  const { theme } = useSelector((state: IStore) => state)

  return (
    <StyledPageHeader
      isNavbar
      title={<CoastWordMarkIcon />}
      extra={
        <Row justify={'end'}>
          <Col span={24}>
            <StyledSpace size="large">
              <RainbowKitButton />
              <ProfileMenu />
            </StyledSpace>
          </Col>
        </Row>
      }
    />
  )
}
