import { Col, Container, Divider, List, Radio, Row } from '@app/components'
import { DynamicListProps } from '@app/types'
import React from 'react'

import { RadioCol, StyledListItem, StyledListItemMeta } from './elements'

export const DynamicList: React.FC<DynamicListProps> = ({
  listData,
  header,
  extra,
  border,
  radioList,
  errorList,
  checkedItem,
  setCheckedItem,
}) => {
  const getExtraItem = (item: any) => {
    if (extra && !item.hideExtra) {
      return extra(item.moreInfo)
    }
    if (item.extra) {
      return item.extra()
    }
    return null
  }

  return (
    <List
      header={header}
      itemLayout="vertical"
      dataSource={listData}
      renderItem={(item) => (
        <StyledListItem extra={getExtraItem(item)} border={border}>
          <Row>
            {radioList && (
              <RadioCol span={2}>
                <Radio
                  checked={checkedItem?.val === item?.val}
                  onChange={() => setCheckedItem({ name: item?.id, val: item?.val })}
                />
              </RadioCol>
            )}
            <Col span={22}>
              <StyledListItemMeta avatar={item.icon} title={item.title} description={item.description} />
            </Col>
          </Row>
          {errorList && (
            <>
              <Container>
                <Divider />
                {item.fix}
              </Container>
            </>
          )}
        </StyledListItem>
      )}
    />
  )
}
