import { PageHeader, Select, Space, Text } from '@app/components'
import { maxMobile } from '@app/styles'
import { Any, PageHeaderProps, SelectProps, SpaceProps, TextProps } from '@app/types'
import styled from 'styled-components'

export const StyledSelect = styled((props: SelectProps<Any>) => <Select {...props} />)`
  && {
    .ant-select-selector {
      background-color: ${(props) => props.theme.color.PRIMARY} !important;
      border: ${(props) => props.theme.color.PRIMARY};
      color: ${(props) => props.theme.color.WHITE};
      height: 40px;
      padding: 0px 30px;
    }
    .ant-select-arrow {
      color: ${(props) => props.theme.color.WHITE};
      margin-right: 10px;
    }
    .ant-select-selection-item {
      color: ${(props) => props.theme.color.WHITE};
      line-height: 38px;
    }
  }
`

export const StyledPageHeader = styled((props: PageHeaderProps) => <PageHeader {...props} />)`
  background-color: ${(props) => props.theme.color?.SECONDARY};
  padding: 15px 50px;
  position: sticky;
  top: 0px;
  z-index: 10;

  @media only screen and (max-width: ${maxMobile}) {
    padding: 6px 25px;
  }

  && {
    .ant-page-header-heading-extra {
      align-items: center;
      display: flex;
    }
    .ant-page-header-heading {
      @media only screen and (max-width: ${maxMobile}) {
        justify-content: center;
      }
    }
  }
`
export const StyledSpace = styled((props: SpaceProps) => <Space {...props} />)`
  flex-wrap: wrap;
`

export const StyledText = styled((props: TextProps) => <Text {...props} />)`
  color: ${(props) => props.theme.color.WHITE};
  font-size: 20px;
`
