export const PiteasContract = {
  abi: [
    {
      type: 'function',
      stateMutability: 'nonpayable',
      outputs: [],
      name: 'changeStatus',
      inputs: [{ type: 'bool', name: '_status', internalType: 'bool' }],
    },
    {
      type: 'function',
      stateMutability: 'nonpayable',
      outputs: [],
      name: 'changeSwapManager',
      inputs: [{ type: 'address', name: '_manager', internalType: 'address' }],
    },
    {
      type: 'function',
      stateMutability: 'view',
      outputs: [{ type: 'address', name: '', internalType: 'address' }],
      name: 'owner',
      inputs: [],
    },
    { type: 'function', stateMutability: 'nonpayable', outputs: [], name: 'renounceOwnership', inputs: [] },
    {
      type: 'function',
      stateMutability: 'payable',
      outputs: [{ type: 'uint256', name: 'returnAmount', internalType: 'uint256' }],
      name: 'swap',
      inputs: [
        {
          type: 'tuple',
          name: 'detail',
          internalType: 'struct PiteasRouter.Detail',
          components: [
            { type: 'address' },
            { type: 'address' },
            { type: 'address' },
            { type: 'uint256' },
            { type: 'uint256' },
          ],
        },
        { type: 'bytes', name: 'data', internalType: 'bytes' },
      ],
    },
    {
      type: 'function',
      stateMutability: 'nonpayable',
      outputs: [],
      name: 'transferOwnership',
      inputs: [{ type: 'address', name: 'newOwner', internalType: 'address' }],
    },
    {
      type: 'function',
      stateMutability: 'nonpayable',
      outputs: [],
      name: 'withdrawFunds',
      inputs: [
        { type: 'address', name: 'token', internalType: 'contract IERC20' },
        { type: 'uint256', name: 'amount', internalType: 'uint256' },
      ],
    },
    {
      type: 'event',
      name: 'ChangedStatus',
      inputs: [{ type: 'bool', name: 'status', indexed: true }],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'ChangedSwapManager',
      inputs: [{ type: 'address', name: 'manager', indexed: true }],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'OwnershipTransferred',
      inputs: [
        { type: 'address', name: 'previousOwner', indexed: true },
        { type: 'address', name: 'newOwner', indexed: true },
      ],
      anonymous: false,
    },
    {
      type: 'event',
      name: 'SwapEvent',
      inputs: [
        { type: 'address', name: 'swapManager', indexed: false },
        { type: 'address', name: 'srcToken', indexed: false },
        { type: 'address', name: 'destToken', indexed: false },
        { type: 'address', name: 'sender', indexed: true },
        { type: 'address', name: 'destReceiver', indexed: false },
        { type: 'uint256', name: 'srcAmount', indexed: false },
        { type: 'uint256', name: 'destAmount', indexed: false },
      ],
      anonymous: false,
    },
    { type: 'error', name: 'ETHTransferFailed', inputs: [] },
    { type: 'error', name: 'EthDepositRejected', inputs: [] },
    { type: 'error', name: 'HasBeenStopped', inputs: [] },
    { type: 'error', name: 'InsufficientBalance', inputs: [] },
    { type: 'error', name: 'InvalidMsgValue', inputs: [] },
    { type: 'error', name: 'ReturnAmountIsNotEnough', inputs: [] },
    { type: 'error', name: 'SafeTransferFailed', inputs: [] },
    { type: 'error', name: 'SafeTransferFromFailed', inputs: [] },
    { type: 'error', name: 'ZeroAddress', inputs: [] },
    { type: 'error', name: 'ZeroData', inputs: [] },
    { type: 'error', name: 'ZeroDestAmount', inputs: [] },
    { type: 'error', name: 'ZeroSrcAmount', inputs: [] },
    { type: 'receive' },
  ],
  address: '0x6BF228eb7F8ad948d37deD07E595EfddfaAF88A6',
}
