import defaultAxios, { AxiosInstance } from 'axios'

const ACCESS_TOKEN_EXPIRY = 1000 * 60 * 1 // 1 minute
//const QUICKBOOKS_AUDIENCE = 'https://quickbooks.api.intuit.com';

class QuickBooksClient {
  private static instance: QuickBooksClient
  private accessToken: string
  private refreshToken: string
  private accessTokenExpiresAt: number

  private readonly clientId: string
  private readonly clientSecret: string

  private readonly axios: AxiosInstance
  private readonly oauthAxios: AxiosInstance

  private authorization: string

  constructor(clientId: string, clientSecret: string, authorization: string) {
    this.clientId = clientId
    this.clientSecret = clientSecret
    this.authorization = authorization

    this.axios = defaultAxios.create({
      baseURL: `${process.env.QUICKBOOKS_BASE_URL}/v3/company/${process.env.QUICKBOOKS_COMPANY_ID}`,
    })

    this.axios.interceptors.request.use(async (config) => {
      const qbAuthorization = await this.getAuthorization()
      config.headers.Authorization = qbAuthorization
      if (this.authorization) {
        config.headers['X-User-Authorization'] = this.authorization
      }
      return config
    })

    this.oauthAxios = defaultAxios.create({
      baseURL: process.env.QUICKBOOKS_OAUTH_URL,
    })
  }

  static getInstance(authorization: string) {
    if (!QuickBooksClient.instance) {
      QuickBooksClient.instance = new QuickBooksClient(
        process.env.QUICKBOOKS_CLIENT_ID,
        process.env.QUICKBOOKS_CLIENT_SECRET,
        authorization,
      )
    }
    return QuickBooksClient.instance
  }

  private isAccessTokenExpired() {
    return Date.now() >= this.accessTokenExpiresAt - ACCESS_TOKEN_EXPIRY
  }

  private async getAuthorization() {
    //if (!this.refreshToken) {
    await this.getRefreshToken()
    await this.refreshAccessToken()
    /*} else if (!this.accessToken || this.isAccessTokenExpired()) {
      await this.refreshAccessToken()
    }*/
    return `Bearer ${this.accessToken}`
  }

  private async getRefreshToken() {
    try {
      const headers = {
        Authorization: this.authorization,
      }

      const response = await defaultAxios.get(process.env.SERVER_URL + '/api/qb/refresh', { headers })
      // eslint-disable-next-line no-console
      console.log('Refresh Token:', response.data.refreshToken)
      this.refreshToken = response.data.refreshToken
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to fetch refresh token:', error)
      throw error
    }
  }

  private async refreshAccessToken() {
    const authHeader = Buffer.from(`${this.clientId}:${this.clientSecret}`).toString('base64')

    const params = new URLSearchParams()
    params.append('grant_type', 'refresh_token')
    params.append('refresh_token', this.refreshToken)

    try {
      const { data } = await this.oauthAxios.post('/tokens/bearer', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Basic ${authHeader}`,
        },
      })

      // eslint-disable-next-line no-console
      console.log('Data:', data)

      if (this.refreshToken !== data.refresh_token) {
        this.refreshToken = data.refresh_token
        await this.updateRefreshTokenOnServer(this.refreshToken)
        // eslint-disable-next-line no-console
        console.log('Refresh token updated on server')
      }
      this.accessToken = data.access_token
      this.accessTokenExpiresAt = Date.now() + data.expires_in * 1000
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to refresh QuickBooks access token:', error)
      throw error
    }
  }

  private async updateRefreshTokenOnServer(newRefreshToken: string) {
    try {
      await defaultAxios.post(process.env.SERVER_URL + 'api/qb/refresh', {
        refreshToken: newRefreshToken,
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Failed to update refresh token on server:', error)
      throw error
    }
  }

  async getToken() {
    if (!this.accessToken || this.isAccessTokenExpired()) {
      await this.refreshAccessToken()
    }
    return this.accessToken
  }

  readonly journalEntries = {
    retrieve_all: async () => {
      const { data } = await this.axios.get('/query?minorversion=70', {
        params: {
          query: 'SELECT * FROM JournalEntry',
        },
      })
      return data
    },
    retrieve: async (id: string) => {
      const { data } = await this.axios.get(`/journalentry/${id}`)
      return data
    },
    create: async (params: any) => {
      const { data } = await this.axios.post('/journalentry', params)
      return data
    },
  }
  readonly accounts = {
    retrieve_all: async () => {
      const { data } = await this.axios.get('/query?minorversion=70', {
        params: {
          query: 'Select * from Account STARTPOSITION 1 MAXRESULTS 5',
        },
      })
      return data
    },
  }
}

export { QuickBooksClient }
