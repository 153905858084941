import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface IUser {
  accessToken: string
  email: string
  emailVerified?: boolean
  uid: string
  firstName?: string
  lastName?: string
  providerId?: 'google.com' | 'password' | 'both'
  fortressIdentityId?: string
  fortressAccountId?: string
  fortressCustodialId?: string
  entityIdentityId?: string
  entityCustodialId?: string
  entityContainerId?: string
  selectedEntityIdentityId?: string
  selectedEntityCustodialId?: string
  selectedEntityContainerId?: string
  selectedEntityName?: string
  registrationStepNo?: number
  entityRegistrationStepNo?: number
  kycStatus?: string
  frozen?: string
  signUpInProgress?: boolean
  referralCode?: string
  referralLeadsCount?: number
  referralConversionsCount?: number
  referralWaitEarned?: number
  referredByCode?: string
  referredConverted?: boolean
  referralPayoutAddress?: string
  idenfyToken?: string
}

const initialState: IUser = {
  accessToken: '',
  email: '',
  uid: '',
}

export const userSlicer = createSlice({
  initialState,
  name: 'user',
  reducers: {
    login: (state, action: PayloadAction<IUser>) => {
      state.accessToken = action.payload.accessToken
      state.email = action.payload.email
      state.emailVerified = action.payload.emailVerified
      state.uid = action.payload.uid
    },
    logout: (state) => {
      state.accessToken = undefined
      state.email = undefined
      state.emailVerified = undefined
      state.uid = undefined
      state.firstName = undefined
      state.lastName = undefined
      state.providerId = undefined
      state.fortressIdentityId = undefined
      state.fortressCustodialId = undefined
      state.fortressAccountId = undefined
      state.entityIdentityId = undefined
      state.entityCustodialId = undefined
      state.entityContainerId = undefined
      state.selectedEntityIdentityId = undefined
      state.selectedEntityCustodialId = undefined
      state.selectedEntityContainerId = undefined
      state.selectedEntityName = undefined
      state.frozen = undefined
      state.kycStatus = undefined
      state.registrationStepNo = undefined
      state.entityRegistrationStepNo = undefined
      state.signUpInProgress = undefined
      state.referralCode = undefined
      state.referralLeadsCount = undefined
      state.referralWaitEarned = undefined
      state.referredByCode = undefined
      state.referredConverted = undefined
      state.referralPayoutAddress = undefined
      state.idenfyToken = undefined
    },
    userUpdate: (
      state,
      action: PayloadAction<
        Pick<
          IUser,
          | 'firstName'
          | 'lastName'
          | 'providerId'
          | 'fortressIdentityId'
          | 'fortressCustodialId'
          | 'frozen'
          | 'fortressAccountId'
          | 'entityIdentityId'
          | 'entityCustodialId'
          | 'entityContainerId'
          | 'selectedEntityIdentityId'
          | 'selectedEntityCustodialId'
          | 'selectedEntityContainerId'
          | 'selectedEntityName'
          | 'kycStatus'
          | 'registrationStepNo'
          | 'entityRegistrationStepNo'
          | 'signUpInProgress'
          | 'referralCode'
          | 'referralLeadsCount'
          | 'referralConversionsCount'
          | 'referralWaitEarned'
          | 'referredByCode'
          | 'referredConverted'
          | 'referralPayoutAddress'
          | 'idenfyToken'
        >
      >,
    ) => {
      if (action.payload.firstName) state.firstName = action.payload.firstName
      if (action.payload.lastName) state.lastName = action.payload.lastName
      if (action.payload.providerId) state.providerId = action.payload.providerId
      if (action.payload.fortressIdentityId) state.fortressIdentityId = action.payload.fortressIdentityId
      if (action.payload.fortressAccountId) state.fortressAccountId = action.payload.fortressAccountId
      if (action.payload.fortressCustodialId) state.fortressCustodialId = action.payload.fortressCustodialId
      if (action.payload.entityIdentityId) state.entityIdentityId = action.payload.entityIdentityId
      if (action.payload.entityCustodialId) state.entityCustodialId = action.payload.entityCustodialId
      if (action.payload.entityContainerId) state.entityContainerId = action.payload.entityContainerId
      if (action.payload.selectedEntityIdentityId)
        state.selectedEntityIdentityId = action.payload.selectedEntityIdentityId
      if (action.payload.selectedEntityCustodialId)
        state.selectedEntityCustodialId = action.payload.selectedEntityCustodialId
      if (action.payload.selectedEntityContainerId)
        state.selectedEntityContainerId = action.payload.selectedEntityContainerId
      if (action.payload.selectedEntityName) state.selectedEntityName = action.payload.selectedEntityName
      if (action.payload.frozen) state.frozen = action.payload.frozen
      if (action.payload.kycStatus) state.kycStatus = action.payload.kycStatus
      if (action.payload.registrationStepNo != null) state.registrationStepNo = action.payload.registrationStepNo
      if (action.payload.entityRegistrationStepNo != null)
        state.entityRegistrationStepNo = action.payload.entityRegistrationStepNo
      if (action.payload.referralCode) state.referralCode = action.payload.referralCode
      if (action.payload.referralLeadsCount != null) state.referralLeadsCount = action.payload.referralLeadsCount
      if (action.payload.referralConversionsCount != null)
        state.referralConversionsCount = action.payload.referralConversionsCount
      if (action.payload.referralWaitEarned != null) state.referralWaitEarned = action.payload.referralWaitEarned
      if (action.payload.referredByCode) state.referredByCode = action.payload.referredByCode
      if (action.payload.referredConverted) state.referredConverted = action.payload.referredConverted
      if (action.payload.referralPayoutAddress) state.referralPayoutAddress = action.payload.referralPayoutAddress
      if (action.payload.idenfyToken) state.idenfyToken = action.payload.idenfyToken
    },
    switchToPersonal: (state) => {
      state.selectedEntityContainerId = undefined
      state.selectedEntityCustodialId = undefined
      state.selectedEntityIdentityId = undefined
      state.selectedEntityName = undefined
    },
  },
})

export const { login, logout, userUpdate, switchToPersonal } = userSlicer.actions

export default userSlicer.reducer
