export const PlsxContract = {
  abi: [
    {
      constant: true,
      inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
      payable: false,
      stateMutability: 'view',
      type: 'function',
    },
  ],
  address: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
}
