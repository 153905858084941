import { EDynamicFormField, IField } from '@app/modules'

import { COUNTRIES } from '../supported-countries'

export const PROFILE_FORM: IField[] = [
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'First Name',
              name: 'firstName',
              placeholder: 'Enter First Name',
              rules: [
                {
                  message: 'Legal First Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Last Name',
              name: 'lastName',
              placeholder: 'Enter Last Name',
              rules: [
                {
                  message: 'Legal Last Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Email',
              name: 'email',
              placeholder: 'Enter Email',
              disabled: true,
              rules: [
                {
                  message: 'Email is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.EMAIL,
            },
            {
              label: 'Country',
              name: 'country',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: COUNTRIES,
            },
          ],
        },
      ],
      title: 'Personal Information',
    },
  },
]

export const ENTITY_FORM: IField[] = [
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Company Name',
              name: 'companyName',
              placeholder: 'Enter Company Name',
              rules: [
                {
                  message: 'Company Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Phone Number',
              name: 'phoneNumber',
              placeholder: 'Enter Phone Number',
              rules: [
                {
                  message: 'Phone Number is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.NUMBER,
            },
            {
              label: 'Email',
              name: 'email',
              placeholder: 'Enter Email',
              disabled: true,
              rules: [
                {
                  message: 'Email is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.EMAIL,
            },
          ],
        },
      ],
      title: 'Entity Information',
    },
  },
]
