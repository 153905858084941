import { getWalletConnectConnector, Wallet } from '@rainbow-me/rainbowkit'

export const internetMoneyMobile = ({ projectId, walletConnectParameters }) => {
  return (): Wallet => {
    const getUri = (uri: string) => `https://internetmoney.io/wc?uri=${encodeURIComponent(uri)}&connector=internetmoney`

    return {
      id: 'internetmoney-mobile',
      name: 'Internet Money (Mobile)',
      iconUrl: '/images/im-wallet-logo.png',
      iconBackground: '#ffffff',
      installed: true, // Adjust according to actual logic
      downloadUrls: {
        android: 'https://internetmoney.io/android',
        ios: 'https://apps.apple.com/us/app/internet-money-wallet/id1641771042',
        mobile: 'https://internetmoney.io/download?utm_source=internetmoneykit',
        qrCode: 'https://internetmoney.io/qrcode',
        browserExtension: 'https://internetmoney.io',
      },
      mobile: { getUri },
      qrCode: {
        getUri,
        instructions: {
          learnMoreUrl: 'https://internetmoney.io/',
          steps: [
            {
              description: 'We recommend putting Internet Money on your home screen for faster access to your wallet.',
              step: 'install',
              title: 'Open the Internet Money app',
            },
            {
              description: 'After you scan, a connection prompt will appear for you to connect your wallet.',
              step: 'scan',
              title: 'Tap the scan button',
            },
          ],
        },
      },
      createConnector: getWalletConnectConnector({
        projectId,
        walletConnectParameters,
      }),
    }
  }
}
