export const TRANSACTION_DATA = [
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
  {
    key: '1',
    type: 'Buy',
    date: 1680588654,
    time: 1680588654,
    amount: 19220,
    currency: 'USDC',
  },
]

export const TRANSACTION_DATA2 = [
  {
    address: 'New York No. 1 Lake Park',
    age: 32,
    amount: '$1,199 USD',
    email: 'amartin@ailane.mil',
    key: '1',
    name: 'John Brown',
  },
  {
    address: 'London No. 1 Lake Park',
    age: 42,
    amount: '$35.00 USD',
    email: 'jigarza@pixoboo.info',
    key: '2',
    name: 'Jim Green',
  },
  {
    address: 'Sidney No. 1 Lake Park',
    age: 32,
    amount: '$15.00 USD',
    email: 'dcole@kwimbee.mil',
    key: '3',
    name: 'Joe Black',
  },
  {
    address: 'Sidney No. 1 Lake Park',
    age: 99,
    amount: '$539 USD',
    email: 'ncoleman@shuffledrive.info',
    key: '4',
    name: 'Aman Shelby',
  },
  {
    address: 'New York No. 1 Lake Park',
    age: 32,
    amount: '$89.00 USD',
    email: 'amartin@ailane.mil',
    key: '5',
    name: 'John Brown',
  },
  {
    address: 'London No. 1 Lake Park',
    age: 42,
    amount: '$432.00 USD',
    email: 'jigarza@pixoboo.info',
    key: '6',
    name: 'Jim Green',
  },
  {
    address: 'Sidney No. 1 Lake Park',
    age: 32,
    amount: '$15.00 USD',
    email: 'jigaz@ihnjos.info',
    key: '7',
    name: 'Joe Black',
  },
  {
    address: 'Sidney No. 1 Lake Park',
    age: 99,
    amount: '$89 USD',
    email: 'ncoleman@shuffledrive.info',
    key: '8',
    name: 'Aman Shelby',
  },
  {
    address: 'Sidney No. 1 Lake Park',
    age: 32,
    amount: '$23.56 USD',
    email: 'roriz@roxoo.mil',
    key: '9',
    name: 'Joe Black',
  },
  {
    address: 'Sidney No. 1 Lake Park',
    age: 99,
    amount: '$340.00 USD',
    email: 'ncoleman@shuffledrive.info',
    key: '10',
    name: 'Aman Shelby',
  },
]
