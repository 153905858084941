export const FORM_FIELDS_DATA = [
  {
    label: 'Single Line Text',
    isRequired: true,
  },
  {
    label: 'Paragraph Text',
    isRequired: false,
  },
  {
    label: 'Single-Select',
    isRequired: false,
  },
  {
    label: 'Multi-Select',
    isRequired: false,
  },
  {
    label: 'Email Address',
    isRequired: false,
  },
]
