import { EDynamicFormField, IField } from '@app/modules'

export const RESET_PASSWORD_FORM: IField[] = [
  {
    label: 'New password',
    name: 'password',
    placeholder: 'Enter password',
    rules: [{ required: true }],
    type: EDynamicFormField.PASSWORD,
  },
  {
    name: 'button',
    placeholder: 'Confirm',
    rules: [{ required: true }],
    title: 'Confirm',
    type: EDynamicFormField.PRIMARY_BUTTON,
  },
]
