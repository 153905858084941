export enum EDynamicFormField {
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  ENTITYDATE = 'ENTITYDATE',
  TEXT_DIVIDER = 'TEXT_DIVIDER',
  EMAIL = 'EMAIL',
  ICON_BUTTON = 'ICON_BUTTON',
  INPUT = 'INPUT',
  LINK = 'LINK',
  IMAGE_CARD = 'IMAGE_CARD',
  PASSWORD = 'PASSWORD',
  CHECKBOX = 'CHECKBOX',
  RADIO = 'RADIO',
  SELECT = 'SELECT',
  SWITCH = 'SWITCH',
  PRIMARY_BUTTON = 'PRIMARY_BUTTON',
  SECONDARY_BUTTON = 'SECONDARY_BUTTON',
  DEFAULT_BUTTON = 'DEFAULT_BUTTON',
  NAVIGATION = 'NAVIGATION',
  RAW_TEXT = 'RAW_TEXT',
  UPLOADER = 'UPLOADER',
}
