import { EDynamicFormField, IField } from '@app/modules'

import { COUNTRIES } from '../supported-countries'

export const ADD_NEW_WIRE_FORM: IField[] = [
  {
    label: 'Name',
    name: 'recName',
    placeholder: 'Enter your name',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'Account Number',
        name: 'accountNumber',
        placeholder: 'Enter account number',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Routing Number',
        name: 'routingNumber',
        placeholder: 'Enter routing number',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },

  {
    label: 'Receiver Address',
    name: 'recAddress',
    placeholder: 'Enter street address',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Receiver Address 2',
    name: 'recAddress2',
    placeholder: 'Enter street address 2',
    rules: [{ required: false }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'City',
        name: 'city',
        placeholder: 'Enter city',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Country',
        name: 'country',
        placeholder: 'Enter Country',
        rules: [
          {
            message: 'Country is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [{ label: 'US', value: 'US' }],
      },
    ],
  },
  {
    inline: [
      {
        label: 'State',
        name: 'region',
        placeholder: 'Enter Your State',
        rules: [
          {
            message: 'State is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [],
      },
      {
        label: 'Postal code',
        name: 'postalCode',
        placeholder: 'Enter postal code',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },
  {
    label: 'Bank Name',
    name: 'recBankName',
    placeholder: 'Enter name of the Bank Account',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Receiver Bank Address',
    name: 'recBankAddress',
    placeholder: 'Enter street address',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Receiver Bank Address 2',
    name: 'recBankAddress2',
    placeholder: 'Enter street address 2',
    rules: [{ required: false }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'City',
        name: 'cityb',
        placeholder: 'Enter city',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Country',
        name: 'countryb',
        placeholder: 'Enter Country',
        rules: [
          {
            message: 'Country is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [{ label: 'US', value: 'US' }],
      },
    ],
  },
  {
    inline: [
      {
        label: 'State',
        name: 'regionb',
        placeholder: 'Enter Your State',
        rules: [
          {
            message: 'State is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [],
      },
      {
        label: 'Postal code',
        name: 'postalCodeb',
        placeholder: 'Enter postal code',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },
  {
    name: 'button',
    title: 'Add Wire Account',
    type: EDynamicFormField.PRIMARY_BUTTON,
  },
]

export const ADD_NEW_INTL_WIRE_FORM: IField[] = [
  {
    label: 'Name',
    name: 'recName',
    placeholder: 'Enter your name',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'Swift Code',
        name: 'swiftCode',
        placeholder: 'Enter swift code',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Bank Account Number',
        name: 'internationalBankAccountNumber',
        placeholder: 'Enter International Bank Account Number',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },

  {
    label: 'Receiver Address',
    name: 'recAddress',
    placeholder: 'Enter street address',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Receiver Address 2',
    name: 'recAddress2',
    placeholder: 'Enter street address 2',
    rules: [{ required: false }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'City',
        name: 'city',
        placeholder: 'Enter city',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Country',
        name: 'country',
        placeholder: 'Enter Country',
        rules: [
          {
            message: 'Country is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: COUNTRIES,
      },
    ],
  },
  {
    inline: [
      {
        label: 'Region',
        name: 'region',
        placeholder: 'Enter Your Region',
        rules: [
          {
            message: 'Region is required',
            required: false,
          },
        ],
        type: EDynamicFormField.INPUT,
        options: [],
      },
      {
        label: 'Postal code',
        name: 'postalCode',
        placeholder: 'Enter postal code',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },
  {
    label: 'Bank Name',
    name: 'recBankName',
    placeholder: 'Enter name of the Bank Account',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Receiver Bank Address',
    name: 'recBankAddress',
    placeholder: 'Enter street address',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Receiver Bank Address 2',
    name: 'recBankAddress2',
    placeholder: 'Enter street address 2',
    rules: [{ required: false }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'Bank City',
        name: 'cityb',
        placeholder: 'Enter city',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Bank Country',
        name: 'countryb',
        placeholder: 'Enter Country',
        rules: [
          {
            message: 'Country is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: COUNTRIES,
      },
    ],
  },
  {
    inline: [
      {
        label: 'Bank Region',
        name: 'regionb',
        placeholder: 'Enter Your Region',
        rules: [
          {
            message: 'Region is required',
            required: false,
          },
        ],
        type: EDynamicFormField.INPUT,
        options: [],
      },
      {
        label: 'Bank Postal code',
        name: 'postalCodeb',
        placeholder: 'Enter postal code',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },
  {
    name: 'button',
    title: 'Add Wire Account',
    type: EDynamicFormField.PRIMARY_BUTTON,
  },
]

export const ADD_NEW_INT_WIRE_FORM: IField[] = [
  {
    label: 'Name',
    name: 'name',
    placeholder: 'Enter name of the Bank Account',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'Account Number',
        name: 'accountNumber',
        placeholder: 'Enter account number',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Routing Number',
        name: 'routingNumber',
        placeholder: 'Enter account number',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },

  {
    label: 'Intermediary Bank Name',
    name: 'intBankName',
    placeholder: 'Enter your intermediary bank name',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Intermediary Address',
    name: 'intAddress',
    placeholder: 'Enter street address of your intermediary bank',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Intermediary Address 2',
    name: 'intAddress2',
    placeholder: 'Enter street address 2 of your intermediary bank',
    rules: [{ required: false }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Intermediary Bank Reference',
    name: 'reference',
    placeholder: 'Enter the reference number of your intermediary bank',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'City',
        name: 'icity',
        placeholder: 'Enter city of your intermediary bank',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Country',
        name: 'icountry',
        placeholder: 'Enter Country of your intermediary bank',
        rules: [
          {
            message: 'Country is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [{ label: 'US', value: 'US' }],
      },
    ],
  },
  {
    inline: [
      {
        label: 'Region',
        name: 'iregion',
        placeholder: 'Enter Your Region of your intermediary bank',
        rules: [
          {
            message: 'Region is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [],
      },
      {
        label: 'Postal code',
        name: 'ipostalCode',
        placeholder: 'Enter postal code of your intermediary bank',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },

  {
    label: 'Beneficiary Bank Name',
    name: 'bankName',
    placeholder: 'Enter your bank name number',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },

  {
    label: 'Beneficiary Address',
    name: 'benAddress',
    placeholder: 'Enter street address',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
  {
    label: 'Beneficiary Address 2',
    name: 'benAddress2',
    placeholder: 'Enter street address 2',
    rules: [{ required: false }],
    type: EDynamicFormField.INPUT,
  },
  {
    inline: [
      {
        label: 'City',
        name: 'city',
        placeholder: 'Enter city',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
      {
        label: 'Country',
        name: 'country',
        placeholder: 'Enter Country',
        rules: [
          {
            message: 'Country is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [{ label: 'US', value: 'US' }],
      },
    ],
  },
  {
    inline: [
      {
        label: 'Region',
        name: 'region',
        placeholder: 'Enter Your Region',
        rules: [
          {
            message: 'Region is required',
            required: true,
          },
        ],
        type: EDynamicFormField.SELECT,
        options: [],
      },
      {
        label: 'Postal code',
        name: 'postalCode',
        placeholder: 'Enter postal code',
        rules: [{ required: true }],
        type: EDynamicFormField.INPUT,
      },
    ],
  },
  {
    label: 'Swift Code',
    name: 'swift',
    placeholder: 'Enter your swift code',
    rules: [{ required: true }],
    type: EDynamicFormField.INPUT,
  },
]
