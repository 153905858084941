import type { AddEthereumChainParameter } from '@web3-react/types'

interface BasicChainInformation {
  urls: string[]
  name: string
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation,
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  } else {
    return chainId
  }
}

// const getInfuraUrlFor = (network: string) =>
//   process.env.NEXT_PUBLIC_INFURA ? `https://${network}.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA}` : undefined
type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }

export const MAINNET_CHAINS: ChainConfig = {
  // 1: {
  //   urls: [getInfuraUrlFor('mainnet'), 'https://cloudflare-eth.com'].filter(Boolean),
  //   name: 'Mainnet',
  // },
  369: {
    urls: ['https://rpc.pulsechain.com'],
    name: 'PulseChain Mainnet',
  },
  // 943: {
  //   urls: ['https://rpc.v4.testnet.pulsechain.com'],
  //   name: 'PulseChain Testnet V4',
  // },
}

export const TESTNET_CHAINS: ChainConfig = {
  // 5: {
  //   // urls: [getInfuraUrlFor('goerli')].filter(Boolean),
  //   urls: ['https://eth-goerli.public.blastapi.io'],
  //   name: 'Görli',
  // },
  943: {
    urls: ['https://rpc.v4.testnet.pulsechain.com'],
    name: 'PulseChain Testnet V4',
  },
  11155111: {
    urls: ['https://eth-sepolia.g.alchemy.com/v2/demo'],
    name: 'Sepolia Testnet',
  },
}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {},
)
