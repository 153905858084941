import { BalanceContext } from '@app/collections/fortress/BalanceContext'
import { Avatar, Dropdown, Notification, Space, Text } from '@app/components'
import { DownOutlined, LogoutOutlined, SettingOutlined, UserOutlined } from '@app/components/icons'
import { ROUTE } from '@app/data'
import { ConfirmationModal } from '@app/generic-collections'
import { IStore } from '@app/redux'
import { auth, errorHandler } from '@app/services'
import { getThemeColors } from '@app/theme'
import type { MenuProps } from '@app/types'
import { toCurrency } from '@app/utils'
import { signOut } from 'firebase/auth'
import useCSTBalance from 'libs/services/hooks/useCSTBalance'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAccount } from 'wagmi'

import { AmericanFlagIcon, CoastIcon } from '~public'

import { StyledButton, StyledText, SubTitleText } from './elements'

export const ProfileMenu: React.FC = () => {
  const { theme, user } = useSelector((state: IStore) => state)
  const router = useRouter()
  const { address } = useAccount()

  const { balance, fetchAccounts } = useContext(BalanceContext)
  const cst_balance = useCSTBalance(false)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const currentStep = user.registrationStepNo

  const handleLogout = async () => {
    try {
      await signOut(auth)
    } catch (error) {
      Notification({ message: error.message, type: 'error' })
      errorHandler.report`Failed to logout: ${error}`
    }
  }

  useEffect(() => {
    fetchAccounts()
  }, [])

  // useEffect(() => {
  //     getCoastBalance()
  // }, [web3reactContext])

  // const getCoastBalance = () => {
  //     // cstBalance(web3reactContext, setCoastBalance)
  // }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <>
          <Space direction="vertical" size={4} onClick={() => router.push(ROUTE.PROFILE)}>
            <Text>
              {user.selectedEntityName ? user.selectedEntityName : user?.firstName || user?.email?.split('@')?.[0]}
            </Text>
            <SubTitleText>{user.email}</SubTitleText>
          </Space>
        </>
      ),
      onClick: () => {
        if (user.frozen == 'True') {
          router.push('/frozen')
        } else if (currentStep < 4) {
          router.push('/account-registration')
        } else {
          router.push(ROUTE.PROFILE)
        }
      },
    },
    {
      type: 'divider',
    },
    {
      icon: <CoastIcon style={{ width: 18, marginRight: 5 }} />,
      key: '2',
      label: address ? `${Number(cst_balance.value) / 10 ** 6}` : 'Connect Wallet',
      onClick: () => {
        router.push('/mint-and-burn')
      },
    },
    {
      icon: <AmericanFlagIcon style={{ width: 16, marginRight: 5 }} />,
      key: '3',
      label: `${toCurrency(balance, false)}`,
      onClick: () => {
        router.push('/mint-and-burn')
      },
    },
    {
      icon: <UserOutlined />,
      key: '4',
      label: 'My Profile',
      onClick: () => {
        if (user.frozen == 'True') {
          router.push('/frozen')
        } else if (currentStep < 4) {
          router.push('/account-registration')
        } else {
          router.push(ROUTE.PROFILE)
        }
      },
    },
    {
      icon: <SettingOutlined />,
      key: '5',
      label: 'Settings',
      onClick: () => {
        if (user.frozen == 'True') {
          router.push('/frozen')
        } else if (currentStep < 4) {
          router.push('/account-registration')
        } else {
          router.push(ROUTE.SETTINGS)
        }
      },
    },
    {
      icon: <LogoutOutlined />,
      key: '6',
      label: 'Log Out',
      onClick: () => setIsModalOpen(true),
    },
  ]
  return (
    <>
      <Dropdown menu={{ items }} trigger={['click']} placement="bottomRight">
        <StyledButton ghost style={{ border: 0 }}>
          <Space>
            <Avatar icon={<UserOutlined />} size={'small'} />
            <StyledText displayMobile="none">
              {user.selectedEntityName ? user.selectedEntityName : user?.firstName || user?.email?.split('@')?.[0]}
            </StyledText>
            <DownOutlined style={{ color: getThemeColors(theme.value).WHITE }} />
          </Space>
        </StyledButton>
      </Dropdown>

      <ConfirmationModal
        onCancel={() => setIsModalOpen(false)}
        onOk={handleLogout}
        open={isModalOpen}
        message={'Are you sure you want to logout'}
        confirmButtonText="Logout"
      />
    </>
  )
}
