export const maxExtraSmall = '479px'
export const maxSmall = '575px'
export const maxMobile = '767px'
export const maxTablet = '991px'
export const maxComputer = '1199px'
export const maxLargeScreen = '1599px'
export const maxExtraLargeScreen = '2999px'
export const maxExtraExtraLargeScreen = '3599px'
export const maxExtremeSmall = '280px'

export const minExtraSmall = '320px'
export const minSmall = '480px'
export const minMobile = '576px'
export const minTablet = '768px'
export const minComputer = '992px'
export const minLargeScreen = '1200px'
export const minExtraLargeScreen = '1600px'
export const minExtraExtraLargeScreen = '3000px'
export const minWidescreen = '1600px'
