export const CARD_DETAILS_DATA = [
  {
    limit: 'Daily',
    price: '15,000 USD',
    refreshTime: '8 hours',
    progressPerc: 20,
  },
  {
    limit: 'Monthly',
    price: '45,000 USD',
    refreshTime: 'Feb 1',
    progressPerc: 0,
  },
]
