import { Avatar } from '@app/components'
import { PlusOutlined } from '@app/components/icons'

import { AmericanFlagIcon, EuropeanFlagIcon, MastercardIcon, VisaIcon } from '~public'

export const ACCOUNT_BALANCE_CARDS = [
  {
    balance: 112,
    type: 'USD',
    currency: 'Digital Account Balance',
    icon: <AmericanFlagIcon />,
  },
]

export const BANK_ACCOUNT_CARDS = [
  {
    balance: '$34',
    currency: 'Test Bank Savings',
    icon: <MastercardIcon />,
  },
  {
    balance: '$1845',
    currency: 'Other Test Bank Checking',
    icon: <VisaIcon />,
  },
]

export const TRANSACTION_LIST = [
  {
    icon: <Avatar icon={<PlusOutlined />} />,
    title: 'To Your USD balance',
    description: 'Added - Yesterday',
    moreInfo: {
      amount: 20.0,
      currency: '20.03 USD',
    },
  },
  {
    icon: <Avatar icon={<PlusOutlined />} />,
    title: 'Euro balance',
    description: 'Added - Today',
    moreInfo: {
      amount: 100,
      currency: '100.03 Euro',
    },
  },
]

export const PAYMENT_RECEIVED_LIST = [
  {
    icon: <AmericanFlagIcon />,
    title: 'US Dollar',
    description: 'Wire routing number, Bank code (SWIFT/BIC), Routing number (ACH or ABA) and Account number',
  },
  {
    icon: <EuropeanFlagIcon />,
    title: 'Euro',
    description: 'Wire routing number, Bank code (SWIFT/BIC), Routing number (ACH or ABA) and Account number',
  },
]
