/* eslint-disable @typescript-eslint/no-unused-vars */
import { Text } from '@app/components'
import { boxProperties } from '@app/styles'
import { TextProps } from '@app/types'
import { Button, ButtonProps } from 'antd'
import { forwardRef } from 'react'
import styled from 'styled-components'

export const StyledButton = styled(
  forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => <Button ref={ref} {...props} type={props.type} />),
)`
  padding: 0px;
`

export const SubTitleText = styled((props: TextProps) => <Text {...props} />)`
  color: 'gray';
  font-size: 12px;
`

export const StyledText = styled(({ displayMobile, ...props }) => <Text {...props} />)`
  color: white;
  ${boxProperties}
`
