import { initializeConnector } from '@web3-react/core'
import { WalletConnect } from '@web3-react/walletconnect-v2'

import { MAINNET_CHAINS } from '../chains'

const data = Object.keys(MAINNET_CHAINS).map(Number)

export const [walletConnect, walletConnectHooks] = initializeConnector<WalletConnect>(
  (actions) =>
    new WalletConnect({
      actions,
      options: {
        projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT,
        chains: data,
        showQrModal: true,
      },
    }),
)

// export const provider = await EthereumProvider.init({
// 	projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT, // REQUIRED your projectId
// 	chains: data, // REQUIRED chain ids
// 	showQrModal, // REQUIRED set to "true" to use @walletconnect/modal
// 	methods, // REQUIRED ethereum methods
// 	optionalMethods, // OPTIONAL ethereum methods
// 	events, // REQUIRED ethereum events
// 	optionalEvents, // OPTIONAL ethereum events
// 	rpcMap, // OPTIONAL rpc urls for each chain
// 	metadata, // OPTIONAL metadata of your app
// 	qrModalOptions // OPTIONAL - `undefined` by default, see https://docs.walletconnect.com/2.0/web3modal/options
//   })
