import { Divider, Link, Text } from '@app/components'
import { EDynamicFormField, IField } from '@app/modules'

import { COUNTRIES, COUNTRIES_PHONE, HIFI_COUNTRIES, HIFI_COUNTRIES_PHONE } from '../supported-countries'

export const REGISTER_ACCOUNT_FORM: IField[] = [
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'First Name',
              name: 'firstName',
              placeholder: 'Enter First Name',
              rules: [
                {
                  message: 'Legal First Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Last Name',
              name: 'lastName',
              placeholder: 'Enter Last Name',
              rules: [
                {
                  message: 'Legal Last Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Date of Birth (YYYY / MM / DD)',
              name: 'dateOfBirth',
              placeholder: 'YYYY / MM / DD',
              rules: [
                {
                  message: 'Date of Birth is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.DATE,
            },
            {
              label: 'Email',
              name: 'email',
              placeholder: 'Enter Email',
              disabled: false,
              rules: [
                {
                  message: 'Email is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.EMAIL,
            },
          ],
        },
        {},
        {
          inline: [
            {
              label: 'Country',
              name: 'countryCode',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: COUNTRIES_PHONE,
            },
            {
              label: 'Phone Number (no country code)',
              name: 'phoneNumber',
              placeholder: 'Enter Phone Number',
              rules: [
                {
                  message: 'Phone Number is required',
                  required: true,
                },
                {
                  message: 'Phone number (no dashes or spaces)',
                  pattern: /^[0-9]{6,12}$/,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Tax ID Number/SSN',
              name: 'taxID',
              type: EDynamicFormField.PASSWORD,

              rules: [
                {
                  required: true,
                },
              ],
            },
          ],
        },
      ],
      title: 'Personal Information',
    },
  },
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Street Address 1',
              name: 'streetAddress_1',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 1 is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Street Address 2',
              name: 'streetAddress_2',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 2 is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Country',
              name: 'country',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: COUNTRIES,
            },

            {
              label: 'State',
              name: 'state',
              placeholder: 'Enter Your State',
              rules: [
                {
                  message: 'State is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: [],
            },
          ],
        },
        {
          inline: [
            {
              label: 'City',
              name: 'city',
              placeholder: 'Enter Your City',
              rules: [
                {
                  message: 'City is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Postal Code (Make sure there is no whitespace!)',
              name: 'postalCode',
              placeholder: 'Enter Postal Code (no whitespace)',
              rules: [
                {
                  message: 'Postal Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
      ],
      title: 'Address',
    },
  },
  // {
  //   name: 'registerAccountTerms',
  //   placeholder: '',
  //   rules: [{ required: true }],
  //   title: (
  //     <Text>
  //       By registering my account, I agree to the Fortress{' '}
  //       <Link href="https://fortresstrust.com/disclosures-e-sign" target="_blank">
  //         E-Sign Diclosure
  //       </Link>{' '}
  //       ,{' '}
  //       <Link href="https://fortresstrust.com/disclosures-consumer" target="_blank">
  //         Consumer Disclosure
  //       </Link>{' '}
  //       and{' '}
  //       <Link href="https://fortresstrust.com/terms-of-use" target="_blank">
  //         Terms of Use
  //       </Link>
  //     </Text>
  //   ),
  //   type: EDynamicFormField.CHECKBOX,
  // },
  {
    name: 'coastAccountTerms',
    placeholder: '',
    rules: [{ required: true }],
    title: (
      <Text>
        By registering my account, I agree to abide by the Coast{' '}
        <Link href="https://0xcoast.com/terms-of-use" target="_blank">
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link href="https://0xcoast.com/privacy-policy" target="_blank">
          Privacy Policy
        </Link>
      </Text>
    ),
    type: EDynamicFormField.CHECKBOX,
  },
  {
    name: 'patriotAct',
    placeholder: '',
    title:
      'IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver\'s license or other identifying documents.',
    type: EDynamicFormField.RAW_TEXT,
  },
  {
    name: 'divider',
    placeholder: '',
    title: <Divider />,
    type: EDynamicFormField.RAW_TEXT,
  },
]

export const REGISTER_HIFI_ACCOUNT_FORM: IField[] = [
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'First Name',
              name: 'firstName',
              placeholder: 'Enter First Name',
              rules: [
                {
                  message: 'Legal First Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Last Name',
              name: 'lastName',
              placeholder: 'Enter Last Name',
              rules: [
                {
                  message: 'Legal Last Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Date of Birth (YYYY / MM / DD)',
              name: 'dateOfBirth',
              placeholder: 'YYYY / MM / DD',
              rules: [
                {
                  message: 'Date of Birth is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.DATE,
            },
            {
              label: 'Email',
              name: 'email',
              placeholder: 'Enter Email',
              disabled: false,
              rules: [
                {
                  message: 'Email is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.EMAIL,
            },
          ],
        },
        {},
        {
          inline: [
            {
              label: 'Country',
              name: 'countryCode',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: HIFI_COUNTRIES_PHONE,
            },
            {
              label: 'Phone Number (no country code)',
              name: 'phoneNumber',
              placeholder: 'Enter Phone Number',
              rules: [
                {
                  message: 'Phone Number is required',
                  required: true,
                },
                {
                  message: 'Phone number (no dashes or spaces)',
                  pattern: /^[0-9]{6,12}$/,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Tax ID Number/SSN',
              name: 'taxID',
              type: EDynamicFormField.PASSWORD,

              rules: [
                {
                  required: true,
                },
              ],
            },
          ],
        },
      ],
      title: 'Personal Information',
    },
  },
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Street Address 1',
              name: 'streetAddress_1',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 1 is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Street Address 2',
              name: 'streetAddress_2',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 2 is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Country',
              name: 'country',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: HIFI_COUNTRIES,
            },

            {
              label: 'State',
              name: 'state',
              placeholder: 'Enter Your State',
              rules: [
                {
                  message: 'State is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: [],
            },
          ],
        },
        {
          inline: [
            {
              label: 'City',
              name: 'city',
              placeholder: 'Enter Your City',
              rules: [
                {
                  message: 'City is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Postal Code (Make sure there is no whitespace!)',
              name: 'postalCode',
              placeholder: 'Enter Postal Code (no whitespace)',
              rules: [
                {
                  message: 'Postal Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
      ],
      title: 'Address',
    },
  },
  {
    card: {
      title: 'Government ID Upload (Front)',
      fields: [
        {
          inline: [
            {
              label: 'Upload Government ID (Front)',
              name: 'govIdFront',
              rules: [
                {
                  message: 'Government ID (Front) is required',
                  required: true,
                },
              ],
              placeholder: 'Upload the front side of your government ID',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },
  {
    card: {
      title: 'Government ID Upload (Back)',
      fields: [
        {
          inline: [
            {
              label: 'Upload Government ID (Back)',
              name: 'govIdBack',
              rules: [
                {
                  message: 'Government ID (Back) is required',
                  required: true,
                },
              ],
              placeholder: 'Upload the back side of your government ID',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },
  {
    card: {
      title: 'Proof of Address Upload (Utility Bill, Bank Statement, etc.)',
      fields: [
        {
          inline: [
            {
              label: 'Proof of Address',
              name: 'proofOfAddress',
              rules: [
                {
                  message: 'Proof of address is required',
                  required: true,
                },
              ],
              placeholder: 'Upload a proof of address document',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },
  {
    name: 'hifiUrl',
    placeholder: '',
    rules: [{ required: true }],
    title: (
      <iframe
        src={'https://www.hifibridge.com/terms'}
        width="100%"
        height="400px"
        style={{ border: '1px solid #ccc', marginBottom: '20px' }}
      />
    ),
    type: EDynamicFormField.RAW_TEXT,
  },
  {
    name: 'coastAccountTerms',
    placeholder: '',
    rules: [{ required: true }],
    title: (
      <Text>
        I have accepted the{' '}
        <Link href="https://www.hifibridge.com/terms" target="_blank">
          Hifi Terms of Service
        </Link>
        .
      </Text>
    ),
    type: EDynamicFormField.CHECKBOX,
  },
]

export const REGISTER_ENTITY_GENERIC_FORM: IField[] = [
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Business Name',
              name: 'businessName',
              placeholder: 'Enter Business Name',
              rules: [
                {
                  message: 'Business Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Business Description',
              name: 'businessDescription',
              placeholder: 'Enter Business Description',
              rules: [
                {
                  message: 'Business Description is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Business Email',
              name: 'businessEmail',
              placeholder: 'Enter Business Email',
              rules: [
                {
                  message: 'Business Email is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Source of Funds',
              name: 'sourceOfFunds',
              placeholder: 'Enter Source of Funds',
              disabled: false,
              rules: [
                {
                  message: 'Source of Funds is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Business Type',
              name: 'businessType',
              placeholder: 'Enter Business Type',
              rules: [
                {
                  message: 'Business Type is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: [
                { label: 'Coop', value: 'Cooperative' },
                { label: 'Corporation', value: 'Corporation' },
                { label: 'LLC', value: 'LLC' },
                { label: 'Partnership', value: 'Partnership' },
                { label: 'Trust', value: 'Trust' },
                { label: 'Sole Proprietorship', value: 'Sole Prop' },
                { label: 'Other', value: 'Other' },
              ],
            },
            {
              label: 'Website',
              name: 'website',
              placeholder: 'Enter Website',
              disabled: false,
              rules: [
                {
                  message: 'Website is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Business Tax ID Number/EIN',
              name: 'businessTaxID',
              type: EDynamicFormField.PASSWORD,

              rules: [
                {
                  required: true,
                },
              ],
            },
          ],
        },
        {},
      ],
      title: 'Entity Information',
    },
  },
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Street Address 1',
              name: 'businessStreetAddress_1',
              placeholder: 'Enter Your Business Street Address',
              rules: [
                {
                  message: 'Street Address 1 is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Street Address 2',
              name: 'businessStreetAddress_2',
              placeholder: 'Enter Your Business Street Address',
              rules: [
                {
                  message: 'Business Street Address 2 is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Business Country',
              name: 'businessCountry',
              placeholder: 'Enter Business Country',
              rules: [
                {
                  message: 'Business Country is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: HIFI_COUNTRIES,
            },

            {
              label: 'Business State',
              name: 'businessState',
              placeholder: 'Enter Your Business State',
              rules: [
                {
                  message: 'Business State is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: [],
            },
          ],
        },
        {
          inline: [
            {
              label: 'Business City',
              name: 'businessCity',
              placeholder: 'Enter Your Business City',
              rules: [
                {
                  message: 'Business City is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Business Postal Code',
              name: 'businessPostalCode',
              placeholder: 'Enter Business Postal Code',
              rules: [
                {
                  message: 'Business Postal Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
      ],
      title: 'Entity Address',
    },
  },
  {
    card: {
      title: 'Formation Document Upload',
      fields: [
        {
          inline: [
            {
              label: 'Upload Formation Document',
              name: 'formationDocument',
              placeholder: 'Upload the Formation Document for your Business',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },
  {
    card: {
      title: 'Proof of Ownership Upload',
      fields: [
        {
          inline: [
            {
              label: 'Upload Proof of Ownership',
              name: 'proofOfOwnership',
              /*rules: [
                {
                  message: 'Proof of Ownership is required',
                  required: true,
                },
              ],*/
              placeholder: 'Upload the Proof of Ownership for your Business',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },

  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'First Name',
              name: 'firstName',
              placeholder: 'Enter First Name',
              rules: [
                {
                  message: 'Legal First Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Last Name',
              name: 'lastName',
              placeholder: 'Enter Last Name',
              rules: [
                {
                  message: 'Legal Last Name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Date of Birth (YYYY / MM / DD)',
              name: 'dateOfBirth',
              placeholder: 'YYYY / MM / DD',
              rules: [
                {
                  message: 'Date of Birth is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.DATE,
            },
            {
              label: 'Email',
              name: 'email',
              placeholder: 'Enter Email',
              disabled: false,
              rules: [
                {
                  message: 'Email is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.EMAIL,
            },
          ],
        },
        {},
        {
          inline: [
            {
              label: 'Country',
              name: 'countryCode',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: COUNTRIES_PHONE,
            },
            {
              label: 'Phone Number (no country code)',
              name: 'phoneNumber',
              placeholder: 'Enter Phone Number',
              rules: [
                {
                  message: 'Phone Number is required',
                  required: true,
                },
                {
                  message: 'Phone number (no dashes or spaces)',
                  pattern: /^[0-9]{6,12}$/,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Tax ID Number/SSN',
              name: 'taxID',
              type: EDynamicFormField.PASSWORD,

              rules: [
                {
                  required: true,
                },
              ],
            },
          ],
        },
      ],
      title: 'Personal Information',
    },
  },
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Street Address 1',
              name: 'streetAddress_1',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 1 is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Street Address 2',
              name: 'streetAddress_2',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 2 is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Country',
              name: 'country',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: HIFI_COUNTRIES,
            },

            {
              label: 'State',
              name: 'state',
              placeholder: 'Enter Your State',
              rules: [
                {
                  message: 'State is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: [],
            },
          ],
        },
        {
          inline: [
            {
              label: 'City',
              name: 'city',
              placeholder: 'Enter Your City',
              rules: [
                {
                  message: 'City is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Postal Code (Make sure there is no whitespace!)',
              name: 'postalCode',
              placeholder: 'Enter Postal Code (no whitespace)',
              rules: [
                {
                  message: 'Postal Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
      ],
      title: 'Personal Address',
    },
  },
  {
    card: {
      title: 'Government ID Upload (Front)',
      fields: [
        {
          inline: [
            {
              label: 'Upload Government ID (Front)',
              name: 'govIdFront',
              /*rules: [
                {
                  message: 'Government ID (Front) is required',
                  required: true,
                },
              ],*/
              placeholder: 'Upload the front side of your government ID',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },
  {
    card: {
      title: 'Government ID Upload (Back)',
      fields: [
        {
          inline: [
            {
              label: 'Upload Government ID (Back)',
              name: 'govIdBack',
              /*rules: [
                {
                  message: 'Government ID (Back) is required',
                  required: true,
                },
              ],*/
              placeholder: 'Upload the back side of your government ID',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },
  {
    card: {
      title: 'Proof of Address Upload (Utility Bill, Bank Statement, etc.)',
      fields: [
        {
          inline: [
            {
              label: 'Proof of Address',
              name: 'proofOfAddress',
              /*rules: [
                {
                  message: 'Proof of address is required',
                  required: true,
                },
              ],*/
              placeholder: 'Upload a proof of address document',
              type: EDynamicFormField.UPLOADER, // Assuming UPLOAD is the type for file upload
              //accept: '.jpg,.jpeg,.png,.pdf', // Specify accepted file types
            },
          ],
        },
      ],
    },
  },
  /*{
    name: 'hifiUrl',
    placeholder: '',
    rules: [{ required: true }],
    title: (
      <iframe
        src={'https://www.hifibridge.com/terms'}
        width="100%"
        height="400px"
        style={{ border: '1px solid #ccc', marginBottom: '20px' }}
      />
    ),
    type: EDynamicFormField.RAW_TEXT,
  },*/
  {
    name: 'coastAccountTerms',
    placeholder: '',
    rules: [{ required: true }],
    title: (
      <Text>
        By registering my account, I agree to abide by the Coast{' '}
        <Link href="https://0xcoast.com/terms-of-use" target="_blank">
          Terms of Use
        </Link>{' '}
        and{' '}
        <Link href="https://0xcoast.com/privacy-policy" target="_blank">
          Privacy Policy
        </Link>
      </Text>
    ),
    type: EDynamicFormField.CHECKBOX,
  },
  {
    name: 'photoUploadTerms',
    placeholder: '',
    rules: [{ required: true }],
    title: (
      <Text>
        The required files must be uploaded for Entity approval, with a total size limit of 4.5 MB. Approval won't
        proceed until the files are uploaded now, or sent to the Coast team via Intercom or emailed to
        support@0xcoast.com.
      </Text>
    ),
    type: EDynamicFormField.CHECKBOX,
  },
]
