// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const FT_URL = process.env.FORTRESS_BASE_URL

/**
 * It takes an optional options object and returns an object with a headers property and an optional
 * options property
 */
const generateAPIConfig = (jwt: string, options?: any) => ({
  headers: {
    'Content-Type': 'application/*+json',
    Authorization: `Bearer ${jwt}`,
  },
  ...(options && { ...options }),
})

export const FORTRESS = {
  FT_URL,
  generateAPIConfig,
}
