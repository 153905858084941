import { createSlice } from '@reduxjs/toolkit'

import { ETheme } from '../../theme/enums'

const initialState = {
  isCompact: false,
  value: ETheme.LIGHT,
}

export const themeSlicer = createSlice({
  initialState,
  name: 'theme',
  reducers: {
    toggleCompactTheme: (state) => {
      state.isCompact = state.isCompact ? false : true
    },
    toggleTheme: (state) => {
      state.value = state.value === ETheme.LIGHT ? ETheme.DARK : ETheme.LIGHT
    },
  },
})

export const { toggleTheme, toggleCompactTheme } = themeSlicer.actions

export default themeSlicer.reducer
