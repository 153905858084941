import { Image } from '@app/components'
import React, { CSSProperties } from 'react'

export const OGBLogo = ({ style }: { style?: CSSProperties }) => {
  const combinedStyle: CSSProperties = {
    //width: '50%', // Set the container width to half
    overflow: 'hidden', // Hide the rest of the image
    backgroundColor: 'blue', // Set the background color of the container
    //position: 'relative', // Position the image within the container
    ...style, // Spread any additional styles passed in
  }

  const imageStyle: CSSProperties = {
    width: '450%', // Double the width of the image
    //transform: 'translateX(-50%)', // Move the image to the left by 50%
  }

  return (
    <div style={combinedStyle}>
      <Image preview={false} src="../images/ogb-logo.svg" style={imageStyle} />
    </div>
  )
}
