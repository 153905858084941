import { EFirestoreCollection } from '@app/services/shared'
import { doc, getDoc } from 'firebase/firestore'

import { db } from '../firebase'

export const getSingle = async (uid) => {
  const data = await getDoc(doc(db, EFirestoreCollection.ACCOUNTS, uid))
  return data.data()
}

export const getTransaction = async (uid) => {
  const data = await getDoc(doc(db, EFirestoreCollection.TRANSACTIONS, uid))
  return data.data()
}

export const getEntity = async (uid) => {
  const data = await getDoc(doc(db, EFirestoreCollection.ENTITIES, uid))
  return data.data()
}

export const getWallets = async (uid) => {
  const data = await getDoc(doc(db, EFirestoreCollection.ACCOUNTS, uid))
  return data.data().wallets
}

export const getHiddenAccounts = async (uid) => {
  const data = await getDoc(doc(db, EFirestoreCollection.ACCOUNTS, uid))
  return data.data().hiddenAccounts
}

export const getDiscountWallet = async (uid) => {
  const data = await getDoc(doc(db, EFirestoreCollection.ACCOUNTS, uid))
  return data.data().discountWallet
}
