import { Divider } from '@app/components'
import { EDynamicFormField, IField } from '@app/modules'

import { COUNTRIES, COUNTRIES_PHONE } from '../supported-countries'

export const REGISTER_ENTITY_FORM: IField[] = [
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Company Name',
              name: 'companyName',
              placeholder: 'Enter Company Name',
              rules: [
                {
                  message: 'Company name is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },

            {
              label: 'Email',
              name: 'email',
              placeholder: 'Enter Email',
              disabled: false,
              rules: [
                {
                  message: 'Email is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.EMAIL,
            },
            {
              label: 'Description',
              name: 'description',
              placeholder: 'Enter Description',
              rules: [
                {
                  message: 'Description is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },

        {},
        {
          inline: [
            {
              label: 'Country Code',
              name: 'countryCode',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: COUNTRIES_PHONE,
            },
            {
              label: 'Phone Number',
              name: 'phoneNumber',
              placeholder: 'Enter Phone Number',
              rules: [
                {
                  message: 'Phone Number is required',
                  required: true,
                },
                {
                  message: 'Phone number (no dashes or spaces)',
                  pattern: /^[0-9]{8,12}$/,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'EIN (US Only)',
              name: 'ein',
              rules: [
                {
                  required: false,
                },
              ],
              type: EDynamicFormField.PASSWORD,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Date Established',
              name: 'establishedOn',
              placeholder: 'YYYY / MM / DD',
              rules: [
                {
                  message: 'Date Established is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.ENTITYDATE,
            },
            {
              label: 'Legal Structure',
              name: 'legalStructure',
              placeholder: 'Ex. LLC',
              rules: [
                {
                  message: 'Legal Structure is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: [
                { label: 'LLC', value: 'llc' },
                { label: 'C-Corp', value: 'ccorp' },
                { label: 'Partnership', value: 'partnership' },
                { label: 'Non-Profit', value: 'nonprofit' },
                { label: 'S-Corp', value: 'scorp' },
                { label: 'Sole-Prop', value: 'soleprop' },
                { label: 'Other', value: 'other' },
              ],
            },
            {
              label: 'Other',
              name: 'otherLegalStructure',
              placeholder: 'Ex. 401k',
              rules: [
                {
                  message: 'Other is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
      ],
      title: 'Entity Information',
    },
  },
  {
    card: {
      fields: [
        {
          inline: [
            {
              label: 'Street Address 1',
              name: 'streetAddress_1',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 1 is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Street Address 2',
              name: 'streetAddress_2',
              placeholder: 'Enter Your Street Address',
              rules: [
                {
                  message: 'Street Address 2 is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'Country',
              name: 'country',
              placeholder: 'Enter Country',
              rules: [
                {
                  message: 'Country is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: COUNTRIES,
            },

            {
              label: 'State (US only)',
              name: 'state',
              placeholder: 'Enter Your State',
              rules: [
                {
                  message: 'State is required',
                  required: false,
                },
              ],
              type: EDynamicFormField.SELECT,
              options: [],
            },
            {
              label: 'Region of Formation',
              name: 'regionOfFormation',
              placeholder: 'Enter the Region of Formation',
              rules: [
                {
                  message: 'Region of Formation is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
        {
          inline: [
            {
              label: 'City',
              name: 'city',
              placeholder: 'Enter Your City',
              rules: [
                {
                  message: 'City is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
            {
              label: 'Postal Code (No dashes or space)',
              name: 'postalCode',
              placeholder: 'Enter Postal Code (no whitespace)',
              rules: [
                {
                  message: 'Postal Code is required',
                  required: true,
                },
              ],
              type: EDynamicFormField.INPUT,
            },
          ],
        },
      ],
      title: 'Entity Address',
    },
  },
  {
    name: 'patriotAct',
    placeholder: '',
    title:
      'IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT: To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an Account. What this means for you: When you open an Account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see a copy of your driver\'s license or other identifying documents.',
    type: EDynamicFormField.RAW_TEXT,
  },
  {
    name: 'divider',
    placeholder: '',
    title: <Divider />,
    type: EDynamicFormField.RAW_TEXT,
  },
]
