export const ROUTE = {
  ACCOUNT_REGISTRATION: '/account-registration',
  AUTH: {
    SIGN_IN: '/auth/sign-in',
    SIGN_UP: '/auth/sign-up',
    FORGOT_PASSWORD: '/auth/forgot-password',
    ACTION: '/auth/action',
    VERIFY_EMAIL: '/auth/verify-email',
  },
  ROOT: '/',
  FROZEN: '/frozen',
  FEES: '/fees',
  SETTINGS: '/settings',
  TRANSACTION: '/transactions',
  PROFILE: '/profile',
  BUY: '/mint-and-burn',
  ANALYTICS: '/analytics',
}
