import { DatePicker } from '@app/components'

import { DynamicInputProps } from '../../types'

export const DFDatePicker: React.FC<DynamicInputProps> = ({ field, disabled }) => {
  return (
    <DatePicker
      placeholder={field.placeholder}
      style={{ width: '100%' }}
      format="YYYY/MM/DD"
      disabled={disabled || field.disabled}
    />
  )
}
