import { theme as Algorithm } from 'antd'
import { ThemeConfig } from 'antd/lib/config-provider/context'

import { getThemeColors } from './color'
import { ETheme } from './enums'

const { defaultAlgorithm, darkAlgorithm, compactAlgorithm } = Algorithm

/* Generates an antd algorithm array based on provided values */
const generateThemeAlgorithm = (theme: ETheme, isCompact: boolean) => {
  const algorithm = []
  if (theme === ETheme.LIGHT) algorithm.push(defaultAlgorithm)
  if (theme === ETheme.DARK) algorithm.push(darkAlgorithm)
  if (isCompact) algorithm.push(compactAlgorithm)
  return algorithm
}

export const THEME = (theme: ETheme, isCompact: boolean): ThemeConfig => {
  const COLOR = getThemeColors(theme)

  return {
    algorithm: generateThemeAlgorithm(theme, isCompact),
    components: {
      Card: {
        colorBgContainer: COLOR.baseBgColor,
        colorBorderSecondary: COLOR.dividerGrey,
      },
      Checkbox: {
        controlInteractiveSize: 23,
      },
      Dropdown: {
        fontSize: 14,
      },
      Form: {
        marginLG: 20,
      },
      Menu: {
        dropdownWidth: 500,
        itemMarginInline: 0,
        radiusItem: 0,
      },
      Steps: {
        descriptionMaxWidth: 220,
      },
      Upload: {
        colorFillAlter: COLOR.grey,
        controlHeightLG: 80,
        lineWidth: 0,
      },
      Segmented: {
        colorBgElevated: COLOR.PRIMARY,
      },
    },
    token: {
      borderRadius: 4,
      colorBgBase: COLOR.baseBgColor,
      colorPrimary: COLOR.PRIMARY,
      controlHeight: 48,
      fontFamily: 'Avenir',
      fontSize: 16,
      fontSizeHeading1: 32,
    },
  }
}
