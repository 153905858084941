import defaultAxios, { AxiosInstance } from 'axios'
import FormData from 'form-data'

import * as Fortress from './types'

const JWT_EXPIRY_BUFFER = 1000 * 60 * 1 // 1 minute
const FORTRESS_AUDIENCE = 'https://fortressapi.com/api'

class FortressClient {
  private static instance: FortressClient
  private jwt: string
  private jwtExpiresAt: number

  private readonly clientId: string
  private readonly username: string
  private readonly password: string

  private readonly axios: AxiosInstance
  private readonly oauthAxios: AxiosInstance

  constructor(clientId: string, username: string, password: string) {
    this.clientId = clientId
    this.username = username
    this.password = password

    this.axios = defaultAxios.create({
      baseURL: `${process.env.FORTRESS_BASE_URL}/api`,
    })

    this.axios.interceptors.request.use(async (config) => {
      const authorization = await this.getAuthorization()
      config.headers.Authorization = authorization
      return config
    })

    this.oauthAxios = defaultAxios.create({
      baseURL: process.env.FORTRESS_OAUTH_URL,
    })
  }

  static getInstance() {
    if (!FortressClient.instance) {
      FortressClient.instance = new FortressClient(
        process.env.FORTRESS_CLIENT_ID,
        process.env.FORTRESS_USERNAME,
        process.env.FORTRESS_PASSWORD,
      )
    }
    return FortressClient.instance
  }

  private isJwtExpired() {
    return Date.now() >= this.jwtExpiresAt + JWT_EXPIRY_BUFFER
  }

  private async getAuthorization() {
    if (!this.jwt || this.isJwtExpired()) {
      await this.obtainToken()
    }
    return `Bearer ${this.jwt}`
  }

  private async obtainToken() {
    const { data } = await this.oauthAxios.post('/oauth/token', {
      grant_type: 'password',
      client_id: this.clientId,
      audience: FORTRESS_AUDIENCE,
      username: this.username,
      password: this.password,
      scope: 'offline_access',
    })

    this.jwt = data.access_token
    this.jwtExpiresAt = Date.now() + data.expires_in * 1000
  }

  async getToken() {
    if (!this.jwt || this.isJwtExpired()) {
      await this.obtainToken()
    }
    return this.jwt
  }

  readonly externalAccounts = {
    retrieve: async (id: string) => {
      const { data } = await this.axios.get<Fortress.ExternalAccount>(`/trust/v1/external-accounts/${id}`)
      return data
    },
    list: async (identityId: string) => {
      const { data } = await this.axios.get<{ data: Fortress.ExternalAccount[] }>(
        `/trust/v1/external-accounts?IdentityId=${identityId}`,
      )
      return data
    },
    createWire: async (params: Fortress.ExternalAccountCreateWireParams) => {
      const { data } = await this.axios.post<Fortress.ExternalAccount>('/trust/v1/external-accounts/wire', params)
      return data
    },
    createACH: async (params: Fortress.ExternalAccountCreateACHParams) => {
      const { data } = await this.axios.post<Fortress.ExternalAccount>('/trust/v1/external-accounts/financial', params)
      return data
    },
    createIntlWire: async (params: Fortress.ExternalAccountCreateIntlWireParams) => {
      const { data } = await this.axios.post<Fortress.ExternalAccount>(
        '/trust/v1/external-accounts/international-wire',
        params,
      )
      return data
    },
    createFundsTransferMethod: async (params: Fortress.ExternalAccountCreateFundsTransferMethodParams) => {
      const { data } = await this.axios.post<Fortress.ExternalAccount>('/trust/v1/external-accounts/financial', params)
      return data
    },
  }

  readonly custodialAccounts = {
    retrieve: async (id: string) => {
      const { data } = await this.axios.get<Fortress.CustodialAccount>(`/trust/v1/custodial-accounts/${id}`)
      return data
    },
    retrieveBalances: async (id: string) => {
      const { data } = await this.axios.get<{ data: Fortress.CustodialAccountBalance[] }>(
        `/trust/v1/custodial-accounts/${id}/balances`,
      )
      return data
    },
    retrieveFiatInstructions: async (id: string, type: Fortress.AssetFiatType) => {
      const { data } = await this.axios.get<Fortress.CustodialAccountFiatDepositInstructions>(
        `/trust/v1/custodial-accounts/${id}/fiat-deposit-instructions/${type}`,
      )
      return data
    },
    create: async (params: Fortress.CustodialAccountCreateParams) => {
      const { data } = await this.axios.post<Fortress.CustodialAccount>('/trust/v1/custodial-accounts', params)
      return data
    },
    startIncomingWireSimulation: async (id: string, params: Fortress.CustodialAccountStartIncomingWireParams) => {
      const { data } = await this.axios.post(`/trust/v1/custodial-accounts/sandbox/${id}/start-incoming-wire`, params)
      return data
    },
    wallet: async (id: string, type: string, network) => {
      const { data } = await this.axios.get(
        `/trust/v1/custodial-accounts/${id}/crypto-deposit-instructions/${network}/${type}`,
      )
      return data
    },
  }

  readonly payments = {
    retrieve: async (id: string) => {
      const { data } = await this.axios.get<Fortress.Payment>(`/trust/v1/payments/${id}`)
      return data
    },
    create: async (params: Fortress.PaymentCreateParams) => {
      const { data } = await this.axios.post<Fortress.Payment>('/trust/v1/payments', params)
      return data
    },
  }

  readonly transactionsFromPayment = {
    retrieve: async (id: string) => {
      const { data } = await this.axios.get<Fortress.Transaction>(`/trust/v1/transactions?paymentId=${id}`)
      return data.data[0].id
    },
  }

  readonly prices = {
    retrieve: async (network: string, currency: string) => {
      try {
        const { data } = await this.axios.get<Fortress.Transaction>(
          `/trust/v1/crypto-currency/crypto-currency-price/${network}/${currency}`,
        )
        return data
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log('error', e.response.data.errors)
      }
    },
  }

  readonly personalIdentities = {
    retrieve: async (id: string) => {
      const { data } = await this.axios.get<Fortress.PersonalIdentity>(`/compliance/v1/personal-identities/${id}`)
      return data
    },
    update: async (id: string, params: Fortress.PersonalIdentityUpdateParams) => {
      const { data } = await this.axios.patch<Fortress.PersonalIdentity>(
        `/compliance/v1/personal-identities/${id}`,
        params,
      )
      return data
    },
  }

  readonly identityContainers = {
    retrieve: async (id: string) => {
      const { data } = await this.axios.get<Fortress.IdentityContainer>(`/compliance/v1/identity-containers/${id}`)
      return data
    },
    create: async (params: Fortress.IdentityContainerCreateParams) => {
      const { data } = await this.axios.post<Fortress.IdentityContainer>('/compliance/v1/identity-containers', params)
      return data
    },
  }

  readonly entityContainers = {
    retrieve: async (id: string) => {
      const { data } = await this.axios.get<Fortress.EntityContainer>(`/compliance/v1/business-identities/${id}`)
      return data
    },
    create: async (params: Fortress.EntityContainerCreateParams) => {
      const { data } = await this.axios.post<Fortress.EntityContainer>('/compliance/v1/business-identities', params)
      return data
    },
  }

  readonly personalDocuments = {
    upload: async (id: string, params: FormData) => {
      const { data } = await this.axios.post<Fortress.PersonalDocument>(
        `/compliance/v1/personal-identities/${id}/documents`,
        params,
        {
          maxBodyLength: Infinity,
          headers: params.getHeaders(),
        },
      )
      return data
    },
  }

  readonly elements = {
    generateSessionJWT: async (identityId: string, element: string) => {
      const { data } = await this.axios.get<{ jwt: string }>(
        `/trust/v1/identity-elements/${identityId}/jwt?element=${element}`,
      )
      return data
    },
  }
}

export { FortressClient }
