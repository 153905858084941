import StackdriverErrorReporter from 'stackdriver-errors-js'

import { auth } from '../firebase'
import { formatError } from './formatters'

const isClient = typeof window !== 'undefined'

const apiKey = process.env.NEXT_PUBLIC_STACKDRIVER_API_KEY

let errorHandler: {
  report(error: Error | string): Promise<void>
  report(errorTemplate: TemplateStringsArray, ...values: any[]): Promise<void>
  setUser: (user: string) => void
}

const convertTemplatedError = (...args: any): Error | string => {
  let error: Error | string
  if (args[0] instanceof Error || typeof args[0] === 'string') {
    error = args[0]
  } else {
    const [errorTemplate, ...values] = args
    error = formatError(errorTemplate, ...values)
  }
  return error
}

if (isClient && !!apiKey) {
  const stackdriver = new StackdriverErrorReporter()
  stackdriver.start({
    key: apiKey,
    projectId: auth.app.options.projectId,

    // Name of the service reporting the error, defaults to 'web'.
    service: 'next-web',

    // Version identifier of the service reporting the error.
    version: process.env.NEXT_PUBLIC_COMMIT_SHA,

    // Set to false to prevent reporting unhandled exceptions, default: `true`.
    // reportUncaughtExceptions: false

    // Set to false to prevent reporting unhandled promise rejections, default: `true`.
    // reportUnhandledPromiseRejections: false

    // Set to true to not send error reports, this can be used when developing locally, default: `false`.
    disabled: !apiKey,

    context: { user: auth.currentUser?.uid || undefined },
  })

  // We wrap the report method to always log the error to the console as well
  errorHandler = {
    report: async (...args: any) => {
      const error = convertTemplatedError(...args)
      // eslint-disable-next-line no-console
      console.error(error)
      try {
        await stackdriver.report(error, {
          // Skip two frames in the error stack to ignore the error reporting wrapper
          skipLocalFrames: 2,
        })
      } catch (reportingError) {
        // The error reporting failed, e.g. due to a network error
        // eslint-disable-next-line no-console
        console.error(reportingError)
      }
    },
    setUser: (user: string) => {
      stackdriver.setUser(user)
    },
  }
} else {
  // 'stackdriver-errors-js' is only available on the client
  errorHandler = {
    report: async (...args: any) => {
      const error = convertTemplatedError(...args)
      // eslint-disable-next-line no-console
      console.error(error)
    },
    setUser: () => {
      // do nothing
    },
  } as any
}

export { errorHandler }
