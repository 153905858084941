import { Col, Input, Row, Title } from '@app/components'

import { StyledButton, StyledCard } from './elements'

export const Jumbotron = ({ title }) => {
  return (
    <StyledCard>
      <Row>
        <Col span={24}>
          <Title level={1} style={{ fontWeight: 100, color: 'white' }}>
            {title}
          </Title>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]}>
            <Col span={24} sm={12} md={10} lg={14}>
              <Input value={'https://coast.com/invite/u/fd66b6fe'} />
            </Col>
            <Col span={24} sm={12} md={6} lg={6}>
              <StyledButton block>Share</StyledButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </StyledCard>
  )
}
