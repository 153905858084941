import { ETheme } from './enums'

const BASE_COLORS = {
  ERROR: '#D8382B',
  PRIMARY: '#2D54A4', //#002AE4 this color is replaced with color mentioned on design file.
  SECONDARY: '#162231',
  WHITE: '#fff',
  baseBgColor: '#fff',
  darkGrey: '#00000072',
  dividerGrey: '#E0E6EE',
  grey: '#f6f6f6',
  tableHighlight: '#abf3ff',
}

const DARK_MODE_COLORS = {
  ...BASE_COLORS,
  baseBgColor: '#121212',
  darkGrey: '#727272',
  dividerGrey: '#727272',
  grey: '#202020',
  tableHighlight: '#2E51A8',
}

export const getThemeColors = (theme?: ETheme) => {
  switch (theme) {
    case ETheme.LIGHT: {
      return {
        ...BASE_COLORS,
      }
    }
    case ETheme.DARK: {
      return {
        ...DARK_MODE_COLORS,
      }
    }
    default: {
      return {
        ...BASE_COLORS,
      }
    }
  }
}
