import { FormItem, Image, Modal, Upload } from '@app/components'
import { Any, UploadProps } from '@app/types'
import { useState } from 'react'

import { UploadIconBlue } from '~public'

import { StyledCard, StyledDescText, StyledSpace, StyledTitleText } from './elements'

export const DFUploader: React.FC<any> = ({ title, name, form }) => {
  const [selectedFile, setSelectedFile] = useState<Any>({})
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')

  const handleChange: UploadProps['onChange'] = ({ fileList }) => {
    const newFileIndex = fileList.length - 1
    const newFile = fileList && fileList[newFileIndex]
    if (newFile?.status === 'done') {
      setSelectedFile((prev) => ({
        ...prev,
        [name]: newFile,
      }))

      if (form) {
        form.setFieldsValue({ [name]: newFile.originFileObj })
      }
    }
  }

  const beforeUpload = (file) => {
    if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
      return true
    } else {
      return false
    }
  }

  const handlePreview = async (file) => {
    setPreviewImage(URL.createObjectURL(selectedFile[name].originFileObj as File))
    setPreviewOpen(true)
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
  }

  const handleCancel = () => setPreviewOpen(false)
  const handleRemove = () => setSelectedFile({})

  const uploadButton = (
    <StyledSpace direction="vertical" size={'small'}>
      <UploadIconBlue />
      <StyledTitleText>{title}</StyledTitleText>
      <StyledDescText>JPG, JPEG, PNG, less than 4 MB</StyledDescText>
    </StyledSpace>
  )

  return (
    <FormItem>
      <StyledCard>
        <Upload
          action={'/api/noop'}
          accept="image/png, image/jpeg, image/jpg"
          beforeUpload={beforeUpload}
          listType="picture-card"
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={handleRemove}
        >
          {selectedFile[name] ? null : uploadButton}
        </Upload>
      </StyledCard>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <Image width={'100%'} alt="preview-image" src={previewImage} preview={false} />
      </Modal>
    </FormItem>
  )
}
