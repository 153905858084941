import { FormItem, Input } from '@app/components'

import { DynamicInputProps } from '../../types'

export const DFInput: React.FC<DynamicInputProps> = ({ field, disabled, validateStatus }) => {
  return (
    <FormItem
      label={field.label}
      name={field.name}
      rules={field.rules}
      validateStatus={validateStatus?.status}
      help={validateStatus?.help}
    >
      <Input name={field.name} placeholder={field.placeholder} disabled={disabled || field.disabled} />
    </FormItem>
  )
}
