import 'node_modules/@rainbow-me/rainbowkit/dist/index.css'

import { ConnectButton } from '@rainbow-me/rainbowkit'
import type { FunctionComponent } from 'react'
import React from 'react'

const RainbowKitButton: FunctionComponent = () => {
  return <ConnectButton accountStatus="address" />
}

export default RainbowKitButton
