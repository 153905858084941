import { IFirestorePagination } from '@app/services/shared'
import {
  collection,
  endBefore,
  getCountFromServer,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore'

import { db } from '../firebase'

// TODO: Optimize  this method
export const fetchPaginatedDocs = async (args: IFirestorePagination, ...other) => {
  const collectionRef = collection(db, args.collection)

  let queryRef: any
  if (args.startAfter) {
    queryRef = query(
      collectionRef,
      orderBy('date', 'desc'),
      startAfter(args.startAfter.date),
      limit(args.limit),
      ...other,
    )
  } else if (args.endBefore) {
    queryRef = query(
      collectionRef,
      orderBy('date', 'desc'),
      endBefore(args.endBefore.date),
      limitToLast(args.limit),
      ...other,
    )
  } else {
    queryRef = query(collectionRef, orderBy('date', 'desc'), limit(args.limit), ...other)
  }
  const snapshot = await getDocs<any>(queryRef) // Fetch documents

  const docs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))

  const [countPrev, countNext] = snapshot.empty
    ? [0, 0]
    : await Promise.all([
      countDocuments(args, orderBy('date', 'desc'), endBefore(snapshot.docs[0]), ...other),
      countDocuments(args, orderBy('date', 'desc'), startAfter(snapshot.docs[snapshot.size - 1]), ...other),
    ])

  return { nodes: docs, countPrev, countNext }
}

export const countDocuments = async (args: IFirestorePagination, ...other) => {
  const collectionRef = collection(db, args.collection)
  const queryRef = query(collectionRef, ...other)
  const snapshot = await getCountFromServer(queryRef)
  const count = snapshot.data().count
  return count
}
