import axios from 'axios'

export const reportData = async (rawTitle: string, rawMessage: string) => {
  // const title = escapeReservedCharacters(rawTitle)
  // const message = escapeReservedCharacters(rawMessage)
  const title = rawTitle
  const message = rawMessage
  try {
    await axios.post(
      process.env.TELEGRAM_URL,
      {
        chat_id: process.env.TELEGRAM_CHAT_ID,
        text: `${title}\n${message}`,
      },
      {},
    )
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}
// const escapeReservedCharacters = (str) => {
//   // List of special characters you want to escape
//   const specialCharacters = ['_', '*', '~', '`', '>', '#', '+', '-', '=', '|', '{', '}', '.', '!']

//   // Create a new RegExp object that matches any of the special characters
//   const regex = new RegExp('[' + specialCharacters.join('\\') + ']', 'g')

//   // Replace any special characters with the escaped version
//   return str.replace(regex, '\\$&')
// }
