import { PulseLockingContract, WaitContract } from 'libs/data/contracts'
import Web3 from 'web3'

const DISCOUNTS = [
  {
    value: 0,
    discount: 4,
  },
  {
    value: 1000,
    discount: 3,
  },
  {
    value: 10000,
    discount: 2,
  },
  {
    value: 100000,
    discount: 1,
  },
]

export async function getDiscount(addy) {
  try {
    // eslint-disable-next-line no-console
    console.log('addy', addy)

    const web3 = new Web3('https://rpc.pulsechain.com')

    const lockingContract: any = new web3.eth.Contract(PulseLockingContract.abi, PulseLockingContract.address)

    //HARDCODED
    const lockedHex: any = await lockingContract.methods.locked(addy, WaitContract.address).call()

    const lockedHexNumber = Number(lockedHex)

    // Select a random discount from the DISCOUNTS array
    //   const randomDiscount = DISCOUNTS[Math.floor(Math.random() * DISCOUNTS.length)]

    //   // Simulate the 'lockedHex' value
    //   const lockedHex = randomDiscount.value * 10**8
    //
    // Find and return the corresponding discount
    const val = DISCOUNTS.find((item) => item.value == lockedHexNumber / 10 ** 8)?.discount
    return val
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('getDiscount error: ', e)
    return 4
  }
}

const BUY_DISCOUNTS = [
  {
    value: 0,
    discount: 1,
  },
  {
    value: 1000,
    discount: 0.75,
  },
  {
    value: 10000,
    discount: 0.5,
  },
  {
    value: 100000,
    discount: 0.25,
  },
]

export async function getBuyDiscount(addy) {
  // 0xeC8d1d1E1bfDB23403B7d5816BE0D43A21Db8C6E
  // eslint-disable-next-line no-console
  console.log('addy', addy)

  const web3 = new Web3('https://rpc.pulsechain.com')

  const lockingContract: any = new web3.eth.Contract(PulseLockingContract.abi, PulseLockingContract.address)

  //HARDCODED
  const lockedHex: any = await lockingContract.methods.locked(addy, WaitContract.address).call()

  const lockedHexNumber = Number(lockedHex)

  // Select a random discount from the DISCOUNTS array
  //   const randomDiscount = DISCOUNTS[Math.floor(Math.random() * DISCOUNTS.length)]

  //   // Simulate the 'lockedHex' value
  //   const lockedHex = randomDiscount.value * 10**8
  //
  // Find and return the corresponding discount
  const val = BUY_DISCOUNTS.find((item) => item.value == lockedHexNumber / 10 ** 8)?.discount
  return val
}
