import { FormItem } from '@app/components'

import { DynamicInputProps } from '../../types'
import { StyledButton } from './elements'

export const DFSecondaryButton: React.FC<DynamicInputProps> = ({ field, onSubmit, loading }) => {
  return (
    <FormItem hidden={field.hidden === true}>
      <StyledButton onClick={() => onSubmit(field.name)} type={'default'} loading={loading || field.loading}>
        {field.title}
      </StyledButton>
    </FormItem>
  )
}
