import React from 'react'

export const AppleIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={15.162} height={18} {...props}>
    <path
      data-name="Path 5448"
      d="M14.844 14.027a9.786 9.786 0 0 1-.968 1.74 8.845 8.845 0 0 1-1.247 1.507 2.419 2.419 0 0 1-1.6.706 4.016 4.016 0 0 1-1.48-.353 4.246 4.246 0 0 0-1.593-.352 4.39 4.39 0 0 0-1.638.352 4.407 4.407 0 0 1-1.416.372 2.277 2.277 0 0 1-1.638-.724 9.283 9.283 0 0 1-1.3-1.56 10.791 10.791 0 0 1-1.379-2.739 10.028 10.028 0 0 1-.579-3.264 5.961 5.961 0 0 1 .783-3.119 4.592 4.592 0 0 1 1.639-1.658 4.41 4.41 0 0 1 2.216-.625 5.219 5.219 0 0 1 1.714.4 5.511 5.511 0 0 0 1.36.4 8.135 8.135 0 0 0 1.508-.471 4.985 4.985 0 0 1 2.049-.364 4.351 4.351 0 0 1 3.409 1.795 3.936 3.936 0 0 0-.763 6.308 4.1 4.1 0 0 0 1.247.818q-.15.435-.318.834ZM11.371.36a3.84 3.84 0 0 1-.984 2.519 3.354 3.354 0 0 1-2.786 1.375 2.8 2.8 0 0 1-.021-.341 3.945 3.945 0 0 1 1.044-2.545A4.021 4.021 0 0 1 9.896.413a3.794 3.794 0 0 1 1.455-.412 3.277 3.277 0 0 1 .019.36Z"
    />
  </svg>
)
