import { Notification } from '@app/components'
import { IStore } from '@app/redux'
import { auth, errorHandler, getSingle } from '@app/services'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const ProfileContext = createContext({
  profileInfo: null as {
    firstName: any
    lastName: any
    email: any
    country: any
  },
  fetchProfile: null as any,
})

export const ProfileProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { user } = useSelector((state: IStore) => state)

  //const [wallets, setWallets] = useState([])
  const [profileInfo, setProfileInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    country: '',
  })

  const fetchProfile = async () => {
    if (!auth.currentUser) return
    try {
      const data = await getSingle(user.uid)

      setProfileInfo({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        country: data.country,
      })
    } catch (error) {
      Notification({ message: error.message, type: 'error' })
      errorHandler.report`Failed to fetch profile: ${error}`
    }
  }

  useEffect(() => {
    fetchProfile()
  }, [])

  const value = useMemo(
    () => ({
      profileInfo,
      fetchProfile,
    }),
    [profileInfo, fetchProfile],
  )

  return <ProfileContext.Provider value={value}> {children} </ProfileContext.Provider>
}
