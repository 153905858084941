import { Button, Card } from '@app/components'
import { ButtonProps, CardProps } from '@app/types'
import styled from 'styled-components'

export const StyledCard = styled((props: CardProps) => <Card {...props} />)`
  background: rgb(45, 84, 164);
  background: linear-gradient(90deg, rgba(45, 84, 164, 1) 0%, rgba(16, 44, 101, 1) 66%, rgba(11, 25, 55, 1) 100%);

  padding: 100px 10px 10px 10px;
`

export const StyledButton = styled((props: ButtonProps) => <Button {...props} />)`
  background: ${(props) => props.theme.color.SECONDARY};
`
