import { Drawer } from '@app/components'
import { CustomDrawerProps } from '@app/types'
import React from 'react'

export const AddNewCardDrawer: React.FC<CustomDrawerProps> = ({ open, toggleOpen }) => {
  return (
    <>
      <Drawer title="Add new card" placement="right" onClose={toggleOpen} open={open}>
        <>Nope</>
      </Drawer>
    </>
  )
}
