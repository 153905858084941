import { Col, FormItem, Input, Row, Text } from '@app/components'
import { DatePickerProps } from '@app/types'

export const DatePicker: React.FC<DatePickerProps> = ({ disabled }) => {
  const validateAge = (_: any, value: string, callback: (message?: string) => void) => {
    const currentYear = new Date().getFullYear()
    const currentMonth = new Date().getMonth() + 1 // getMonth() returns month index starting from 0
    const currentDay = new Date().getDate()

    const inputYearElement = document.querySelector('input[name="year"]') as HTMLInputElement
    const inputYear = parseInt(inputYearElement.value, 10)

    const inputMonthElement = document.querySelector('input[name="month"]') as HTMLInputElement
    const inputMonth = parseInt(inputMonthElement.value, 10)

    const inputDayElement = document.querySelector('input[name="day"]') as HTMLInputElement
    const inputDay = parseInt(inputDayElement.value, 10)

    if (isNaN(inputYear) || isNaN(inputMonth) || isNaN(inputDay)) {
      callback()
      return
    }

    if (
      currentYear - inputYear < 18 ||
      (currentYear - inputYear === 18 &&
        (inputMonth > currentMonth || (inputMonth === currentMonth && inputDay > currentDay)))
    ) {
      callback('You must be over 18 years old!')
    } else if (
      inputDay > 31 ||
      inputMonth > 12 ||
      inputDay < 1 ||
      inputMonth < 1 ||
      inputYear < 1900 ||
      inputYear > currentYear
    ) {
      callback('Invalid Date')
    } else {
      callback()
    }
  }

  return (
    <Row>
      <Col span={7}>
        <FormItem
          label={'Year'}
          name={'year'}
          rules={[
            {
              message: 'Year is Required',
              required: true,
            },
            {
              message: 'Year must be 4 digits long',
              pattern: /^[0-9]{4}$/,
            },
            {
              validator: validateAge,
            },
          ]}
        >
          <Input name={'year'} placeholder={'YYYY'} width="100%" disabled={disabled} />
        </FormItem>
      </Col>
      <Text style={{ marginTop: '35px', fontSize: '30px' }}>/</Text>
      <Col span={7}>
        <FormItem
          label={'Month'}
          name={'month'}
          rules={[
            {
              validator: validateAge,
            },
            {
              message: 'Month is Required',
              required: true,
            },
            {
              message: 'Month must be 2 digits long',
              pattern: /^[0-9]{2}$/,
            },
          ]}
        >
          <Input name={'month'} placeholder={'MM'} width="100%" disabled={disabled} />
        </FormItem>
      </Col>
      <Text style={{ marginTop: '35px', fontSize: '30px' }}>/</Text>

      <Col span={7}>
        <FormItem
          label={'Day'}
          name={'day'}
          rules={[
            {
              validator: validateAge,
            },
            {
              message: 'Day is Required',
              required: true,
            },
            {
              message: 'Day must be 2 digits long',
              pattern: /^[0-9]{2}$/,
            },
          ]}
        >
          <Input name={'day'} placeholder={'DD'} width="100%" disabled={disabled} />
        </FormItem>
      </Col>
    </Row>
  )
}
