import { Drawer, Tabs, useWatch } from '@app/components'
import { wireStates } from '@app/data'
import { ADD_NEW_INT_WIRE_FORM, ADD_NEW_WIRE_FORM } from '@app/forms'
import { DynamicForm, generateDynamicFields } from '@app/modules'
import { CustomDrawerProps, TabsProps } from '@app/types'
import React, { useState } from 'react'

export const AddNewWireDrawer: React.FC<CustomDrawerProps> = ({ open, toggleOpen, form, onSubmit }) => {
  const watcher = { country: useWatch('country', form), icountry: useWatch('icountry', form) }

  const [activeTab, setActiveTab] = useState('domestic')

  const handleTabChange = (value: string) => {
    setActiveTab(value)
  }

  const items: TabsProps['items'] = [
    {
      children: (
        <DynamicForm
          fields={generateDynamicFields(ADD_NEW_WIRE_FORM, [{ name: 'region', options: wireStates[watcher.country] }])}
          form={form}
          onSubmit={onSubmit}
        />
      ),
      key: 'domestic',
      label: 'Domestic',
    },
    {
      children: (
        <DynamicForm
          fields={generateDynamicFields(ADD_NEW_INT_WIRE_FORM, [
            { name: 'region', options: wireStates[watcher.country] },
            { name: 'iregion', options: wireStates[watcher.icountry] },
          ])}
          form={form}
          onSubmit={onSubmit}
        />
      ),
      key: 'international',
      label: 'International',
    },
  ]

  return (
    <>
      <Drawer title="Add new wire account" placement="right" onClose={toggleOpen} open={open}>
        <Tabs activeKey={activeTab} items={items} onChange={handleTabChange} />
      </Drawer>
    </>
  )
}
