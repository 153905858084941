/* eslint-disable react/no-unknown-property */
import React, { Fragment } from 'react'

export const AppFonts = () => (
  <Fragment>
    <style global jsx>{`
      @font-face {
        font-family: 'Avenir';
        src: url('/fonts/AvenirNextLTPro-Regular.otf') format('opentype');
        font-weight: normal;
        font-style: normal;
      }

      @font-face {
        font-family: 'Avenir';
        src: url('/fonts/AvenirNextLTPro-Medium.otf') format('opentype');
        font-weight: bold;
        font-style: normal;
      }

      @font-face {
        font-family: 'Avenir';
        src: url('/fonts/AvenirNextLTPro-Italic.otf') format('opentype');
        font-weight: normal;
        font-style: italic;
      }
    `}</style>
  </Fragment>
)

export default AppFonts
