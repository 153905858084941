import { FormItem } from '@app/components'

import { DynamicInputProps } from '../../types'
import { StyledButton } from './elements'

export const DFPrimaryButton: React.FC<DynamicInputProps> = ({ field, onSubmit, disabled, loading }) => {
  return (
    <FormItem>
      <StyledButton
        htmlType="submit"
        block
        type="primary"
        onClick={() => onSubmit(field.name)}
        disabled={disabled || field.disabled}
        loading={loading || field.loading}
      >
        {field.title}
      </StyledButton>
    </FormItem>
  )
}
