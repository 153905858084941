/* eslint-disable no-console */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

// Create an Axios client instance
const Axios: AxiosInstance = axios.create()

// Request interceptor
// Axios.interceptors.request.use(
//   (config: AxiosRequestConfig) => {
//     console.log(config.method.toUpperCase(), config.url)
//     return config
//   },
//   (error: AxiosError) => {
//     return Promise.reject(error)
//   },
// )

// Response interceptor
Axios.interceptors.response.use(
  (response: AxiosResponse) => {
    console.log(response.config.method.toUpperCase(), response.status, response.config.url)
    return response
  },
  (error: AxiosError) => {
    console.log(error.response.config.method.toUpperCase(), error.response.status, error.config.url)
    console.log(error.response.data)
    return Promise.reject(error)
  },
)

export { Axios }
