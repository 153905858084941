export const TEST_WALLETS = [
  {
    label: 'Wallet 1',
    value: '0x12379864593795',
  },
  {
    label: 'Wallet 2',
    value: '0x7564674834593795',
  },
]
