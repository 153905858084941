export const TRANSFER_HISTORY_DATA = [
  {
    amount: '$1,199 USD',
    convertedTo: '185 BTC',
    date: '12/25/2022',
    email: 'amartin@ailane.mil',
    key: '1',
    name: 'John Brown',
    status: 'Cancelled',
    to: 'Amanda Smith',
  },
  {
    amount: '$35.00 USD',
    convertedTo: '14 BTC',
    date: '12/12/2021',
    email: 'jigarza@pixoboo.info',
    key: '2',
    name: 'Jim Green',
    status: 'Confirmed',
    to: 'David Hasin',
  },
  {
    amount: '$15.00 USD',
    convertedTo: '28 BTC',
    date: '02/25/2018',
    email: 'dcole@kwimbee.mil',
    key: '3',
    name: 'Joe Black',
    status: 'Pending',
    to: 'Tahen Ravi',
  },
  {
    amount: '$539 USD',
    convertedTo: '10 BTC',
    date: '05/12/2019',
    email: 'ncoleman@shuffledrive.info',
    key: '4',
    name: 'Aman Shelby',
    status: 'Cancelled',
    to: 'Sheril Aman',
  },
  {
    amount: '$89.00 USD',
    convertedTo: '0 BTC',
    date: '01/10/2022',
    email: 'amartin@ailane.mil',
    key: '5',
    name: 'John Brown',
    status: 'Approved',
    to: 'Jim Amanda',
  },
  {
    amount: '$432.00 USD',
    convertedTo: '1 BTC',
    date: '02/01/2018',
    email: 'jigarza@pixoboo.info',
    key: '6',
    name: 'Jim Green',
    status: 'Pending',
    to: 'Pixoboo Shelby',
  },
  {
    amount: '$15.00 USD',
    convertedTo: '0 BTC',
    date: '02/07/2022',
    email: 'jigaz@ihnjos.info',
    key: '7',
    name: 'Joe Black',
    status: 'Cancelled',
    to: 'Tahen Aham',
  },
  {
    amount: '$89 USD',
    convertedTo: '0 BTC',
    date: '12/25/2022',
    email: 'ncoleman@shuffledrive.info',
    key: '8',
    name: 'Aman Shelby',
    status: 'Cancelled',
    to: 'John David',
  },
  {
    amount: '$23.56 USD',
    convertedTo: '12 BTC',

    date: '01/04/2020',
    email: 'roriz@roxoo.mil',
    key: '9',
    name: 'Joe Black',
    status: 'Approved',
    to: 'Renbir Hassim',
  },
  {
    amount: '$340.00 USD',
    convertedTo: '15 BTC',

    date: '16/06/2022',
    email: 'ncoleman@shuffledrive.info',
    key: '10',
    name: 'Aman Shelby',
    status: 'Pending',
    to: 'Helyn Lavid',
  },
]
