import { Notification } from '@app/components'
import { buyStates } from '@app/data'
import { IStore } from '@app/redux'
import { auth, errorHandler, getSingle } from '@app/services'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

export const UserInfoContext = createContext({
  balance: null as number,
  usdMoved: null as number,
  usa: null as boolean,
  country: null as string,
  state: null as boolean,
  idenfy: null as boolean,
  setUserBalance: null as React.Dispatch<React.SetStateAction<number>>,
  setUserUsdMoved: null as React.Dispatch<React.SetStateAction<number>>,
  setUserUSA: null as React.Dispatch<React.SetStateAction<boolean>>,
  setUserCountry: null as React.Dispatch<React.SetStateAction<string | null>>,
  setUserState: null as React.Dispatch<React.SetStateAction<boolean>>,
  setUserIdenfy: null as React.Dispatch<React.SetStateAction<boolean>>,
  fetchUserInfo: null as () => Promise<void>,
})

export const UserInfoProvider: React.FC<React.PropsWithChildren<{ userLoading: boolean }>> = ({
  children,
  userLoading,
}) => {
  const { user } = useSelector((state: IStore) => state)

  const [balance, setUserBalance] = useState(0)
  const [usdMoved, setUserUsdMoved] = useState(0)
  const [usa, setUserUSA] = useState(null)
  const [country, setUserCountry] = useState<string | null>(null)
  const [state, setUserState] = useState<boolean>(false)
  const [idenfy, setUserIdenfy] = useState<boolean>(false)

  const fetchUserInfo = async () => {
    if (userLoading) return
    if (user?.registrationStepNo != 4 || !auth.currentUser) return
    try {
      const userDoc = await getSingle(user.uid)

      const moved = userDoc.USD_Moved ? userDoc.USD_Moved : 0

      const userUSD = userDoc.USD_Balance ? userDoc.USD_Balance : 0

      const country = userDoc.country
      const state = !buyStates.US.some((item) => item.value === userDoc.usState)
      const idenfy = userDoc.scanRef && userDoc.registrationStepNo === 4
      const usa = userDoc.USA

      setUserBalance(userUSD)
      setUserUsdMoved(moved)
      setUserUSA(usa)
      setUserCountry(country)
      setUserState(state)
      setUserIdenfy(idenfy)
    } catch (error) {
      Notification({ message: error.message, type: 'error' })
      errorHandler.report`Failed to fetch user info in context: ${error}`
    }
  }

  useEffect(() => {
    fetchUserInfo()
  }, [userLoading])

  const value = useMemo(
    () => ({
      balance,
      usdMoved,
      usa,
      country,
      state,
      idenfy,
      setUserBalance,
      setUserUsdMoved,
      setUserUSA,
      setUserCountry,
      setUserState,
      setUserIdenfy,
      fetchUserInfo,
    }),
    [balance, usdMoved, usa, country, state, idenfy, fetchUserInfo],
  )

  return <UserInfoContext.Provider value={value}> {children} </UserInfoContext.Provider>
}
