/* eslint-disable sort-keys */
export const countryStates = {
  US: [
    {
      label: 'Alaska',
      value: 'AK',
    },
    {
      label: 'Alabama',
      value: 'AL',
    },
    {
      label: 'Arkansas',
      value: 'AR',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'CT',
    },
    {
      label: 'District of Columbia',
      value: 'DC ',
    },
    {
      label: 'Delaware',
      value: 'DE',
    },
    {
      label: 'Florida',
      value: 'FL',
    },
    {
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Hawaii',
      value: 'HI',
    },
    {
      label: 'Iowa',
      value: 'IA',
    },
    {
      label: 'Idaho',
      value: 'ID',
    },
    {
      label: 'Illinois',
      value: 'IL',
    },
    {
      label: 'Indiana',
      value: 'IN',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'KY',
    },
    {
      label: 'Louisiana',
      value: 'LA',
    },
    {
      label: 'Massachusetts',
      value: 'MA',
    },
    {
      label: 'Maryland',
      value: 'MD',
    },
    {
      label: 'Maine',
      value: 'ME',
    },
    {
      label: 'Michigan',
      value: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'MN',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'Mississippi',
      value: 'MS',
    },
    {
      label: 'Montana',
      value: 'MT',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'ND',
    },
    {
      label: 'Nebraska',
      value: 'NE',
    },
    {
      label: 'New Hampshire',
      value: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'NM',
    },
    {
      label: 'New York',
      value: 'NY',
    },
    {
      label: 'Nevada',
      value: 'NV',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'OK',
    },
    {
      label: 'Oregon',
      value: 'OR',
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
    },
    {
      label: 'Puerto Rico',
      value: 'PR',
    },
    {
      label: 'Rhode Island',
      value: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },
    {
      label: 'Texas',
      value: 'TX',
    },
    {
      label: 'Utah',
      value: 'UT',
    },
    {
      label: 'Virginia',
      value: 'VA',
    },
    {
      label: 'Virgin Islands',
      value: 'VI',
    },
    {
      label: 'Vermont',
      value: 'VT',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'Wisconsin',
      value: 'WI',
    },
    {
      label: 'West Virginia',
      value: 'WV',
    },
    {
      label: 'Wyoming',
      value: 'WY',
    },
  ],
  England: [],
  Australia: [],
  Canada: [],
  'New Zealand': [],
  Singapore: [],
  Ireland: [],
  Mexico: [],
  Switzerland: [],
  Sweden: [],
  Portugal: [],
  Italy: [],
  Norway: [],
  Netherlands: [],
  France: [],
  Finland: [],
  Estonia: [],
  Denmark: [],
  Croatia: [],
  Belgium: [],
  Spain: [],
  Poland: [],
  Germany: [],
  UAE: [],
  India: [],
  'South Africa': [],
  Austria: [],
  Argentina: [],
  Brazil: [],
  Brunei: [],
  Bulgaria: [],
  Chile: [],
  'Czech Republic': [],
  Egypt: [],
  Greece: [],
  Hungary: [],
  Indonesia: [],
  Israel: [],
  Japan: [],
  Luxembourg: [],
  Malaysia: [],
  Philippines: [],
  Qatar: [],
  Romania: [],
  'Saudi Arabia': [],
  Slovakia: [],
  Slovenia: [],
  'South Korea': [],
  Taiwan: [],
  Thailand: [],
  Turkey: [],
  Vietnam: [],
  Nigeria: [],
  Bangladesh: [],
  Ethiopia: [],
  Congo: [],
  Tanzania: [],
  Myanmar: [],
  Kenya: [],
  Algeria: [],
  Uganda: [],
  Morocco: [],
  Angola: [],
  Ghana: [],
  Mozambique: [],
  Peru: [],
  Uzbekistan: [],
  Nepal: [],
  Cameroon: [],
  'Cote d`Ivoire': [],
  Madagascar: [],
  Niger: [],
  'Sri Lanka': [],
  'Burkina Faso': [],
  Mali: [],
  Malawi: [],
  Zambia: [],
  Kazakhstan: [],
  Chad: [],
  Senegal: [],
  Guatemala: [],
  Ecuador: [],
  Cambodia: [],
  'Hong Kong': [],
  Serbia: [],
  'El Salvador': [],
  Cyprus: [],
  Iceland: [],
  Bahamas: [],
  Aruba: [],
  Barbados: [],
  'Virgin Islands': [],
  'Isle of Man': [],
  'Marshall Islands': [],
  Bermuda: [],
  'Cayman Islands': [],
  Liechtenstein: [],
  'British Virgin Islands': [],
  Monaco: [],
}

export const hifiStates = {
  USA: [
    /*{
      label: 'Alaska',
      value: 'AK',
    },*/
    {
      label: 'Alabama',
      value: 'AL',
    },
    {
      label: 'Arkansas',
      value: 'AR',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'CT',
    },
    {
      label: 'District of Columbia',
      value: 'DC ',
    },
    {
      label: 'Delaware',
      value: 'DE',
    },
    /*{
      label: 'Florida',
      value: 'FL',
    },*/
    {
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Hawaii',
      value: 'HI',
    },
    {
      label: 'Iowa',
      value: 'IA',
    },
    {
      label: 'Idaho',
      value: 'ID',
    },
    {
      label: 'Illinois',
      value: 'IL',
    },
    {
      label: 'Indiana',
      value: 'IN',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'KY',
    },
    /*{
      label: 'Louisiana',
      value: 'LA',
    },*/
    {
      label: 'Massachusetts',
      value: 'MA',
    },
    {
      label: 'Maryland',
      value: 'MD',
    },
    {
      label: 'Maine',
      value: 'ME',
    },
    {
      label: 'Michigan',
      value: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'MN',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'Mississippi',
      value: 'MS',
    },
    {
      label: 'Montana',
      value: 'MT',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'ND',
    },
    {
      label: 'Nebraska',
      value: 'NE',
    },
    {
      label: 'New Hampshire',
      value: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'NM',
    },
    /*{
      label: 'New York',
      value: 'NY',
    },*/
    {
      label: 'Nevada',
      value: 'NV',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'OK',
    },
    {
      label: 'Oregon',
      value: 'OR',
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
    },
    {
      label: 'Puerto Rico',
      value: 'PR',
    },
    {
      label: 'Rhode Island',
      value: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },
    {
      label: 'Texas',
      value: 'TX',
    },
    {
      label: 'Utah',
      value: 'UT',
    },
    {
      label: 'Virginia',
      value: 'VA',
    },
    {
      label: 'Virgin Islands',
      value: 'VI',
    },
    {
      label: 'Vermont',
      value: 'VT',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'Wisconsin',
      value: 'WI',
    },
    {
      label: 'West Virginia',
      value: 'WV',
    },
    {
      label: 'Wyoming',
      value: 'WY',
    },
  ],
  /*England: [],
  Australia: [],
  Canada: [],
  'New Zealand': [],
  Singapore: [],
  Ireland: [],
  Mexico: [],
  Switzerland: [],
  Sweden: [],
  Portugal: [],
  Italy: [],
  Norway: [],
  Netherlands: [],
  France: [],
  Finland: [],
  Estonia: [],
  Denmark: [],
  Croatia: [],
  Belgium: [],
  Spain: [],
  Poland: [],
  Germany: [],
  UAE: [],
  India: [],
  'South Africa': [],
  Austria: [],
  Argentina: [],
  Brazil: [],
  Brunei: [],
  Bulgaria: [],
  Chile: [],
  'Czech Republic': [],
  Egypt: [],
  Greece: [],
  Hungary: [],
  Indonesia: [],
  Israel: [],
  Japan: [],
  Luxembourg: [],
  Malaysia: [],
  Philippines: [],
  Qatar: [],
  Romania: [],
  'Saudi Arabia': [],
  Slovakia: [],
  Slovenia: [],
  'South Korea': [],
  Taiwan: [],
  Thailand: [],
  Turkey: [],
  Vietnam: [],
  Nigeria: [],
  Bangladesh: [],
  Ethiopia: [],
  Congo: [],
  Tanzania: [],
  Myanmar: [],
  Kenya: [],
  Algeria: [],
  Uganda: [],
  Morocco: [],
  Angola: [],
  Ghana: [],
  Mozambique: [],
  Peru: [],
  Uzbekistan: [],
  Nepal: [],
  Cameroon: [],
  'Cote d`Ivoire': [],
  Madagascar: [],
  Niger: [],
  'Sri Lanka': [],
  'Burkina Faso': [],
  Mali: [],
  Malawi: [],
  Zambia: [],
  Kazakhstan: [],
  Chad: [],
  Senegal: [],
  Guatemala: [],
  Ecuador: [],
  Cambodia: [],
  'Hong Kong': [],
  Serbia: [],
  'El Salvador': [],
  Cyprus: [],
  Iceland: [],
  Bahamas: [],
  Aruba: [],
  Barbados: [],
  'Virgin Islands': [],
  'Isle of Man': [],
  'Marshall Islands': [],
  Bermuda: [],
  'Cayman Islands': [],
  Liechtenstein: [],
  'British Virgin Islands': [],
  Monaco: [],*/
}

export const wireStates = {
  US: [
    {
      label: 'Alaska',
      value: 'AK',
    },
    {
      label: 'Alabama',
      value: 'AL',
    },
    {
      label: 'Arkansas',
      value: 'AR',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'CT',
    },
    {
      label: 'District of Columbia',
      value: 'DC ',
    },
    {
      label: 'Delaware',
      value: 'DE',
    },
    {
      label: 'Florida',
      value: 'FL',
    },
    {
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Hawaii',
      value: 'HI',
    },
    {
      label: 'Iowa',
      value: 'IA',
    },
    {
      label: 'Idaho',
      value: 'ID',
    },
    {
      label: 'Illinois',
      value: 'IL',
    },
    {
      label: 'Indiana',
      value: 'IN',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'KY',
    },
    {
      label: 'Louisiana',
      value: 'LA',
    },
    {
      label: 'Massachusetts',
      value: 'MA',
    },
    {
      label: 'Maryland',
      value: 'MD',
    },
    {
      label: 'Maine',
      value: 'ME',
    },
    {
      label: 'Michigan',
      value: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'MN',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'Mississippi',
      value: 'MS',
    },
    {
      label: 'Montana',
      value: 'MT',
    },
    {
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'ND',
    },
    {
      label: 'Nebraska',
      value: 'NE',
    },
    {
      label: 'New Hampshire',
      value: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'NM',
    },
    {
      label: 'New York',
      value: 'NY',
    },
    {
      label: 'Nevada',
      value: 'NV',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'OK',
    },
    {
      label: 'Oregon',
      value: 'OR',
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
    },
    {
      label: 'Puerto Rico',
      value: 'PR',
    },
    {
      label: 'Rhode Island',
      value: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },
    {
      label: 'Texas',
      value: 'TX',
    },
    {
      label: 'Utah',
      value: 'UT',
    },
    {
      label: 'Virginia',
      value: 'VA',
    },
    {
      label: 'Virgin Islands',
      value: 'VI',
    },
    {
      label: 'Vermont',
      value: 'VT',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'Wisconsin',
      value: 'WI',
    },
    {
      label: 'West Virginia',
      value: 'WV',
    },
    {
      label: 'Wyoming',
      value: 'WY',
    },
  ],
  England: [],
  Australia: [],
  Canada: [],
  'New Zealand': [],
  Singapore: [],
  Ireland: [],
  Mexico: [],
  Switzerland: [],
  Sweden: [],
  Portugal: [],
  Italy: [],
  Norway: [],
  Netherlands: [],
  France: [],
  Finland: [],
  Estonia: [],
  Denmark: [],
  Croatia: [],
  Belgium: [],
  Spain: [],
  Poland: [],
  Germany: [],
  UAE: [],
  India: [],
  'South Africa': [],
  Austria: [],
  Argentina: [],
  Brazil: [],
  Brunei: [],
  Bulgaria: [],
  Chile: [],
  'Czech Republic': [],
  Egypt: [],
  Greece: [],
  Hungary: [],
  Indonesia: [],
  Israel: [],
  Japan: [],
  Luxembourg: [],
  Malaysia: [],
  Philippines: [],
  Qatar: [],
  Romania: [],
  'Saudi Arabia': [],
  Slovakia: [],
  Slovenia: [],
  'South Korea': [],
  Taiwan: [],
  Thailand: [],
  Turkey: [],
  Vietnam: [],
  Nigeria: [],
  Bangladesh: [],
  Ethiopia: [],
  Congo: [],
  Tanzania: [],
  Myanmar: [],
  Kenya: [],
  Algeria: [],
  Uganda: [],
  Morocco: [],
  Angola: [],
  Ghana: [],
  Mozambique: [],
  Peru: [],
  Uzbekistan: [],
  Nepal: [],
  Cameroon: [],
  'Cote d`Ivoire': [],
  Madagascar: [],
  Niger: [],
  'Sri Lanka': [],
  'Burkina Faso': [],
  Mali: [],
  Malawi: [],
  Zambia: [],
  Kazakhstan: [],
  Chad: [],
  Senegal: [],
  Guatemala: [],
  Ecuador: [],
  Cambodia: [],
  'Hong Kong': [],
  Serbia: [],
  'El Salvador': [],
  Cyprus: [],
  Iceland: [],
  Bahamas: [],
  Aruba: [],
  Barbados: [],
  'Virgin Islands': [],
  'Isle of Man': [],
  'Marshall Islands': [],
  Bermuda: [],
  'Cayman Islands': [],
  Liechtenstein: [],
  'British Virgin Islands': [],
  Monaco: [],
}

export const buyStates = {
  US: [
    /*{
      label: 'Alaska',
      value: 'AK',
    },
    {
      label: 'Alabama',
      value: 'AL',
    },
    {
      label: 'Arkansas',
      value: 'AR',
    },
    {
      label: 'Arizona',
      value: 'AZ',
    },
    {
      label: 'California',
      value: 'CA',
    },
    {
      label: 'Colorado',
      value: 'CO',
    },
    {
      label: 'Connecticut',
      value: 'CT',
    },
    {
      label: 'District of Columbia',
      value: 'DC ',
    },
    {
      label: 'Delaware',
      value: 'DE',
    },*/
    {
      label: 'Florida',
      value: 'FL',
    },
    /*{
      label: 'Georgia',
      value: 'GA',
    },
    {
      label: 'Hawaii',
      value: 'HI',
    },
    {
      label: 'Iowa',
      value: 'IA',
    },
    {
      label: 'Idaho',
      value: 'ID',
    },
    {
      label: 'Illinois',
      value: 'IL',
    },
    {
      label: 'Indiana',
      value: 'IN',
    },
    {
      label: 'Kansas',
      value: 'KS',
    },
    {
      label: 'Kentucky',
      value: 'KY',
    },
    {
      label: 'Louisiana',
      value: 'LA',
    },
    {
      label: 'Massachusetts',
      value: 'MA',
    },
    {
      label: 'Maryland',
      value: 'MD',
    },
    {
      label: 'Maine',
      value: 'ME',
    },
    {
      label: 'Michigan',
      value: 'MI',
    },
    {
      label: 'Minnesota',
      value: 'MN',
    },
    {
      label: 'Missouri',
      value: 'MO',
    },
    {
      label: 'Mississippi',
      value: 'MS',
    },*/
    {
      label: 'Montana',
      value: 'MT',
    },
    /*{
      label: 'North Carolina',
      value: 'NC',
    },
    {
      label: 'North Dakota',
      value: 'ND',
    },
    {
      label: 'Nebraska',
      value: 'NE',
    },
    {
      label: 'New Hampshire',
      value: 'NH',
    },
    {
      label: 'New Jersey',
      value: 'NJ',
    },
    {
      label: 'New Mexico',
      value: 'NM',
    },
    {
      label: 'New York',
      value: 'NY',
    },
    {
      label: 'Nevada',
      value: 'NV',
    },
    {
      label: 'Ohio',
      value: 'OH',
    },
    {
      label: 'Oklahoma',
      value: 'OK',
    },
    {
      label: 'Oregon',
      value: 'OR',
    },
    {
      label: 'Pennsylvania',
      value: 'PA',
    },
    {
      label: 'Puerto Rico',
      value: 'PR',
    },
    {
      label: 'Rhode Island',
      value: 'RI',
    },
    {
      label: 'South Carolina',
      value: 'SC',
    },
    {
      label: 'South Dakota',
      value: 'SD',
    },
    {
      label: 'Tennessee',
      value: 'TN',
    },*/
    {
      label: 'Texas',
      value: 'TX',
    },
    /*{
      label: 'Utah',
      value: 'UT',
    },
    {
      label: 'Virginia',
      value: 'VA',
    },
    {
      label: 'Virgin Islands',
      value: 'VI',
    },
    {
      label: 'Vermont',
      value: 'VT',
    },
    {
      label: 'Washington',
      value: 'WA',
    },
    {
      label: 'Wisconsin',
      value: 'WI',
    },
    {
      label: 'West Virginia',
      value: 'WV',
    },
    {
      label: 'Wyoming',
      value: 'WY',
    },*/
  ],
}
