import { Text } from '@app/components'
import {
  BankOutlined,
  BarChartOutlined,
  DollarOutlined,
  FireOutlined,
  PercentageOutlined,
  RetweetOutlined,
  SettingOutlined,
  StarOutlined,
  SwapOutlined,
  UserOutlined,
  WalletOutlined,
} from '@app/components/icons'
import { MenuProps } from '@app/types'
import MenuItem from 'antd/es/menu/MenuItem'

type MenuItem = Required<MenuProps>['items'][number]

const ITEMS = [
  {
    icon: <BankOutlined />,
    key: 'deposit-and-withdraw',
    label: 'Deposit / Withdraw',
    route: '/deposit-and-withdraw',
  },
  {
    icon: <DollarOutlined />,
    key: 'mint-and-burn',
    label: 'Mint / Burn',
    route: '/mint-and-burn',
  },
  {
    icon: <WalletOutlined />,
    key: 'buy',
    label: (
      <>
        <Text style={{ color: '#d7d7d7' }}>Buy</Text>
        <Text style={{ color: '#d7d7d7', marginLeft: '95px', marginRight: '5px' }}>New</Text>
        <FireOutlined style={{ color: '#D8382B' }} />
      </>
    ),
    route: '/buy',
  },
  {
    icon: <RetweetOutlined />,
    key: 'swap',
    label: 'Swap',
    route: '/swap',
  },
  {
    icon: <SwapOutlined />,
    key: 'transactions',
    label: 'Transactions',
    route: '/transactions',
  },
  {
    icon: <PercentageOutlined />,
    key: 'fees',
    label: 'Fees',
    route: '/fees',
  },
  {
    icon: <BarChartOutlined />,
    key: 'analytics',
    label: 'Analytics',
    route: '/analytics',
  },
  {
    icon: <UserOutlined />,
    key: '/profile',
    label: 'Profile',
    route: '/profile',
  },
  {
    icon: <SettingOutlined />,
    key: 'settings',
    label: 'Settings',
    route: '/settings',
  },
  {
    icon: <StarOutlined />,
    key: 'referrals',
    label: 'Refer a Friend',
    route: '/referrals',
  },
]

export const SIDEBAR_MENU_ITEMS = (onClick: (route: string) => void): MenuItem[] => {
  return ITEMS.map((item) => ({
    icon: item.icon,
    key: item.key,
    label: item.label,
    onClick: () => onClick(item.route),
  }))
}
